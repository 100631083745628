/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import super_admin_routes from "../super-admin-routes";
import Team from "views/examples/static/Team";
import About from "views/examples/static/About";
import Faq from "views/examples/static/Faq";
import Chat from "views/examples/Chat";

//import routes from "routes.js";
import admin_routes from "admin-routes.js";
import { AuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import SinglePurchase from "views/examples/SinglePurchase";
import PurchaseUserDetails from "../views/examples/user/PurchaseUserDetails";
import UserSummary from "../views/examples/user/UserSummary";
import InvoicePage from "views/examples/InvoicePage";
import ProtectionAdmin from "ProtectionAdmin";
import Agb from "views/examples/static/Agb";
//import { AuthContextProvider } from "context/AuthContext";

const Admin = (props) => {

  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { connected, user } = useContext(AuthContext);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;

  }, [location]);

  // React.useEffect(() => {
  //
  //   if(!connected){
  //     history.push("/auth/login");
  //   }else{
  //     history.push("/admin/index");
  //   }
  //
  // }, []);

  const getRoutes = (admin_routes) => {
    return admin_routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < admin_routes.length; i++) {
/*       if (props.location.pathname.indexOf(admin_routes[i].layout + admin_routes[i].path) !== -1) {
        return admin_routes[i].name;
      } */
      return admin_routes[i].name;
    }
    return "";
  };


  return (
    <>
      <Sidebar
          {...props}
          routes= {admin_routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/logo_dodo.png").default,
            imgAlt: "...",
          }}
      />
  {/*    {user?.is_super_admin === 1 ? <Sidebar
          {...props}
          routes= {super_admin_routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/plicanlogo.PNG").default,
            imgAlt: "...",
          }}
      /> :
          <Sidebar
          {...props}
          routes= {admin_routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/plicanlogo.PNG").default,
            imgAlt: "...",
          }}
      />
      }*/}

      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText()}
        />
        <Switch>
          {user?.is_super_admin === 1 ? getRoutes(super_admin_routes) : getRoutes(admin_routes)}
          <Route path="/admin/plican-team" render={(props) => <ProtectionAdmin {...props} Cmp={Team} />} />
          <Route path="/admin/about-us" render={(props) => <ProtectionAdmin {...props} Cmp={About}  />} />
          <Route path="/admin/f-a-q" render={(props) => <ProtectionAdmin {...props} Cmp={Faq}  />} />
          <Route path="/admin/agb" render={(props) => <ProtectionAdmin {...props} Cmp={Agb}  />} />
          <Route path="/admin/single-purchase" render={(props) => <ProtectionAdmin {...props} Cmp={SinglePurchase}  />} />
          <Route path="/admin/users/details" render={(props) => <ProtectionAdmin {...props} Cmp={UserSummary}  />} />
          <Route path="/admin/users/purchase/details" render={(props) => <ProtectionAdmin {...props} Cmp={PurchaseUserDetails}  />} />
          <Route path="/admin/invoice" render={(props) => <ProtectionAdmin {...props} Cmp={InvoicePage} />} />
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>

    </>
  );
};

export default Admin;