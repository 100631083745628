import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col, InputGroup, Input,
} from "reactstrap";

import React, {useContext, useEffect, useRef, useState} from "react";
import '../filestyle.css';
import AdminMainHeader from "../../../components/Headers/AdminMainHeader";
import {useForm} from "react-hook-form";
import InputBase from "@material-ui/core/InputBase";
import * as UserServices from "../../../services/UserServices";
import * as AdminServices from "../../../services/AdminServices";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import UserMainHeader from "../../../components/Headers/UserMainHeader";
import moment from "moment";
import UserHeader from "../../../components/Headers/UserHeader";
import {AuthContext} from "../../../context/AuthContext";
import ReactLoading from "react-loading";
//import {MEDIA_URL} from "../../../constants";
import ReactPaginate from "react-paginate";
import {getPaginatedUsersWithRange, updateAdminInfos} from "../../../services/AdminServices";
import env from "ts-react-dotenv";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneInput from "react-phone-number-input";
import {resendVerificationMail} from "../../../services/AuthServices";
import {Link} from "react-router-dom";
import {computeNumerotation} from "../../../constants";

const AVATAR_URL = env.MEDIA_URL;
const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const ManageUsers = () => {


    const inputRef = useRef(null);
    const {afterLogin} = useContext(AuthContext);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [danger, setDanger] = useState(false);
    const [openUserManagement, setOpenUserManagement] = useState(false);
    const [activeObject, setActiveObject] = useState(null);

    const [admins, setAdmins] = useState(null);
    const [openNews, setOpenNews] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [openVehicle, setOpenVehicle] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [isLoading, setLoading] = useState(true)
    const [isUpdating, setUpdating] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEdition, setOpenEdition] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openAlert, setOpenAlert] = useState(false);

    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("");
    const [id, setId] = useState("");
    const [status, setStatus] = useState("")

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseDanger = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDanger(false);
    };

    const handleNews = () => {
        setOpenNews(!openNews);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleArticle = () => {
        setOpenArticle(!openArticle);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleVehicle = () => {
        setOpenVehicle(!openVehicle);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleUserInterface = () => {
        setOpenUserManagement(!openUserManagement);
        setOpenTable(!openTable);
        setOpenEdition(!openEdition);
    };

    const handleEdition = () => {
        setOpenEdition(!openEdition);
    };

    const banishUser = async () => {
        //const id = e.target.getAttribute("id");
        setIsUpdatingStatus(true);
        await UserServices.bannishUser(id);
        setOpenUserManagement(false);
        setOpenEdition(!openEdition)
        setOpenTable(!openTable);
        setIsUpdatingStatus(false);
        await retrieveAdmins();
    }

    const deleteAdmin = async () => {
        await AdminServices.deleteAdmin(id);
        //setDanger(true);
        setAlertMessage("The user has been deleted successfully.");
        setAlertType("success")
        handleOpenAlert()
        handleDeleteModal();
        await retrieveAdmins();
    }

    const retrieveAdmins = async () => {
        let admins = await AdminServices.getAllUsers();
        //console.log('domain created sucessfully');
        setAdmins(admins);
    }

    const retrieveUsersPaginated = async (page) => {
        let response = await AdminServices.getPaginatedUsers(page, itemPerPage);
        console.log(response);
        setAdmins(response.data);
        setCurrentPage(response.current_page);
    }

    const handlePageClick = (data) => {
        const requestedPage = data.selected + 1;
        retrieveUsersPaginated(requestedPage);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        let response = await AdminServices.getPaginatedUsersWithRange(parseInt(nb));
        setAdmins(response.data);
        setLastPage(response.last_page);
        setCurrentPage(response.current_page);
    }

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleActiveObject = (activeObject) => {
        console.log(activeObject)
        setId(activeObject?.id);
        setEmail(activeObject?.email);
        setUsername(activeObject?.username);
        setFirstName(activeObject?.first_name);
        setLastName(activeObject?.last_name);
        setPhoneNumber(activeObject?.phone);
        setCountry(activeObject?.country);
        setStatus(activeObject?.status);
        setActiveObject({activeObject});
    };

    useEffect(() => {
        const retrieveUsersPaginated = async () => {
            let response = await AdminServices.getPaginatedUsers();
            console.log(response);
            setAdmins(response.data);
            setLastPage(response.last_page);
            setCurrentPage(response.current_page);
            setLoading(false);
        }
        retrieveUsersPaginated();
    }, []);

    const completeInfos = async (e) => {
        e.preventDefault();
        const data = {
            email: email,
            username: username,
            last_name: lastName,
            first_name: firstName,
            country: country,
            phone: phoneNumber
        }
        console.log("les données")
        console.log(data)
        try {
            setUpdating(true);
            await updateAdminInfos(id, data);
            setAlertMessage("Information successfully updated !");
            setAlertType("success");
            setUpdating(false)
            handleOpenAlert();
            await afterLogin();
        } catch (error) {
            console.log(error)
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const resendMail = async () => {
        try {
            setIsSending(true);
            await resendVerificationMail(email);
            setAlertMessage("Confirmation mail has beean sent sucessfully !");
            setAlertType("success");
            handleOpenAlert();
            setIsSending(false);
            await afterLogin();
        } catch (error) {
            console.log(error)
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const editEmail = (event) => {
        const result = event.target.value;
        setEmail(result);
    };


    const {user} = useContext(AuthContext);
    return (
        <>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={6000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openDeleteModal} onClose={handleDeleteModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Delete user</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this user ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={
                            handleDeleteModal
                        } color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            id={activeObject?.id}
                            onClick={deleteAdmin}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <UserMainHeader handleNews={handleNews} handleArticle={handleArticle} handleVehicle={handleVehicle}
                            off={off}/>
            {/* Page content */}
            {openEdition ?
                <Container className="mt--7" fluid>
                    <Row>
                        {/*<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">*/}
                        <Col className="order-xl-1" xl="12">
                            <Card className="card-profile shadow">
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                            {/*<a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                                            {/*    <img*/}
                                            {/*        alt="..."*/}
                                            {/*        className="rounded-circle"*/}
                                            {/*        src={MEDIA_URL + user?.avatar}*/}
                                            {/*    />*/}
                                            {/*</a>*/}
                                        </div>
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            className="mr-4"
                                            color="danger"
                                            // onClick={(e) => e.preventDefault()}
                                            size="sm"
                                            id={activeObject?.id}
                                            onClick={handleUserInterface}
                                        >
                                            Cancel
                                        </Button>


                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0 pt-md-4">
                                    <Row>
                                        <div className="col">

                                            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                                {status === 'ACCEPTED' ?
                                                    <>
                                                        <Button
                                                            className="mr-4"
                                                            color="danger"
                                                            // onClick={(e) => e.preventDefault()}
                                                            size="sm"
                                                            id={activeObject?.id}
                                                            onClick={banishUser}
                                                        >
                                                            Banish
                                                        </Button>
                                                        <span>{isSending ?
                                                            <ReactLoading type={"spin"} color="yellow" height={30}
                                                                          width={(30)}/> : null}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <Button
                                                            className="mr-4"
                                                            color="info"
                                                            size="sm"
                                                            id={activeObject?.id}
                                                            onClick={banishUser}
                                                        >
                                                            Restore
                                                        </Button>
                                                        <span>{isUpdatingStatus ?
                                                            <ReactLoading type={"spin"} color="yellow" height={30}
                                                                          width={(30)}/> : null}</span>
                                                    </>
                                                }
                                                <Button
                                                    className="float-right"
                                                    color="secondary"
                                                    onClick={resendMail}
                                                    size="sm"
                                                >
                                                    Resend confirmation email
                                                </Button>
                                                <span>{isSending ? <ReactLoading type={"spin"} color="yellow" height={30}
                                                                                 width={(30)}/> : null}</span>


                                            </div>
                                            <br/><br/>
                                            <center>
                                                <h2>User informations</h2>
                                                <Form>
                                                    <Table className="align-activeObjects-center table-flush"
                                                           responsive>
                                                        <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td>Email</td>
                                                            <td>
                                                                <Input
                                                                    value={email}
                                                                    className="form-control-alternative"
                                                                    onChange={editEmail}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                            </td>
                                                            <td>Username</td>
                                                            <td>
                                                                <Input
                                                                    value={username}
                                                                    onChange={
                                                                        (e) => {
                                                                            setUsername(e.target.value)
                                                                        }}
                                                                    className="form-control-alternative"


                                                                />
                                                                {/*{errors.username &&*/}
                                                                {/*    <FormHelperText style={{color: 'red'}}>User name is required*/}
                                                                {/*        !</FormHelperText>}*/}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td><span>First name </span></td>
                                                            <td>
                                                                <Input
                                                                    value={firstName}
                                                                    className="form-control-alternative"
                                                                    ref={inputRef}
                                                                    onChange={
                                                                        (e) => {
                                                                            setFirstName(e.target.value)
                                                                        }}
                                                                />
                                                                {/*{errors.first_name &&*/}
                                                                {/*    <FormHelperText style={{color: 'red'}}>First name is required*/}
                                                                {/*        !</FormHelperText>}*/}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td><span>Last name </span></td>
                                                            <td>
                                                                <Input
                                                                    value={lastName}
                                                                    className="form-control-alternative"
                                                                    ref={inputRef}
                                                                    onChange={
                                                                        (e) => {
                                                                            setLastName(e.target.value)
                                                                        }}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>Phone</td>
                                                            <td>
                                                                <Input
                                                                    value={phoneNumber}
                                                                    className="form-control-alternative"
                                                                    onChange={
                                                                        (e) => {
                                                                            setPhoneNumber(e.target.value)
                                                                        }}
                                                                />

                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>Country</td>
                                                            <td>
                                                                <Input
                                                                    value={country}
                                                                    className="form-control-alternative"
                                                                    onChange={
                                                                        (e) => {
                                                                            setCountry(e.target.value)
                                                                        }}
                                                                />
                                                                {/*{errors.country &&*/}
                                                                {/*    <FormHelperText style={{color: 'red'}}>Country is required*/}
                                                                {/*        !</FormHelperText>}*/}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <Button
                                                                    className="my-4"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={completeInfos}
                                                                    disabled={isUpdating}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <span>{isUpdating ?
                                                                    <ReactLoading type={"spin"} color="yellow"
                                                                                  height={30}
                                                                                  width={(30)}/> : null}</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </Form>
                                            </center>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container> : ""}

            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Users</h3>
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spin"} color="yellow"/> :
                                <Table className="align-activeObject?s-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Avatar</th>
                                        <th scope="col">UserName(s)</th>
                                        <th scope="col">Last name(s)</th>
                                        <th scope="col">First name(s)</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">...</th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        admins?.map((activeObject, index) =>
                                            <tr key={activeObject?.id}>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                                    </span>
                                                </th>
                                                <td>
                                                    <div className="avatar-group">

                                                        <a
                                                            className="avatar avatar-sm"
                                                            href="#pablo"
                                                            id="tooltip941738690"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            <img
                                                                alt="..."
                                                                className="rounded-circle"
                                                                src={AVATAR_URL + activeObject?.avatar}
                                                            />
                                                        </a>
                                                    </div>
                                                </td>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {activeObject?.username}
                                                    </span>
                                                </th>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {activeObject?.first_name}
                                                    </span>
                                                </th>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {activeObject?.last_name}
                                                    </span>
                                                </th>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {activeObject?.email}
                                                    </span>
                                                </th>
                                                <td>
                                                    {activeObject?.status}
                                                </td>

                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                            <span
                                                                className="material-icons"
                                                                style={{cursor: 'pointer', color: 'dodgerblue'}}
                                                                onClick={() => {
                                                                    handleActiveObject(activeObject);
                                                                    handleUserInterface();
                                                                    handleEdition();
                                                                }}
                                                            >edit

                                                            </span>
                                                        <Tooltip title="Delete" placement="top">
                                                                <span
                                                                    className='material-icons'
                                                                    id={activeObject?.id}
                                                                    onClick={() => {
                                                                        setActiveObject({activeObject});
                                                                        handleActiveObject(activeObject);
                                                                        handleDeleteModal();
                                                                    }}
                                                                    style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                    delete
                                                                </span>
                                                        </Tooltip>
                                                        <Link to={{
                                                            pathname: '/admin/users/details',
                                                            search: '?userId=' + activeObject?.id
                                                        }}>
                                                        <span
                                                            className='material-icons danger'
                                                            style={{cursor: 'pointer', color: 'dodgerblue'}}
                                                            // onClick={() => {
                                                            //     setActiveObject({activeObject?});
                                                            //     handleDetails();
                                                            // }}
                                                        >
                                                                visibility
                                                            </span>
                                                        </Link>
                                                        {/*<span*/}
                                                        {/*    className='material-icons danger'*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        setActiveObject({activeObject?});*/}
                                                        {/*        handleUserInterface();*/}
                                                        {/*    }}*/}
                                                        {/*    style={{cursor: 'pointer', color: 'pink'}}>*/}
                                                        {/*                build*/}
                                                        {/*            </span>*/}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>}
                            <div className={classes.root}>
                                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="success">
                                        User well created !
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={danger} autoHideDuration={2000} onClose={handleCloseDanger}>
                                    <Alert onClose={handleCloseDanger} severity="error">
                                        User cancelled !
                                    </Alert>
                                </Snackbar>
                            </div>
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
        </>
    );
};

export default ManageUsers;
