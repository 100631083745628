import axios from "axios";

export const getCategory = (id) => {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedCategories(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/categories/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/categories/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function getChildCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/child/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getDescentCategories(id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/category/${id}/descent`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const createCategory = (data, image, selectedFeatures, selectedChildCategories) => {
    let feature_ids = JSON.stringify(selectedFeatures);
    let category_ids = JSON.stringify(selectedChildCategories);
/*     const toSend = {
        name : data.name,
        description : data.description,
        category_ids : category_ids,
        feature_ids : feature_ids,
        type : data.type,
    } */

    let toSend = new FormData();

    toSend.append("name", data.name);
    toSend.append("description", data.description);
    if(image !== null){
        toSend.append("image", image.file);
    }
    toSend.append("category_ids", category_ids);
    toSend.append("feature_ids", feature_ids);
    toSend.append("type", data.type);


    return new Promise((resolve, reject) => {
        axios
            .post(`/create/category`, toSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const updateCategory = (data, categoryId, eImage, deleteImage, selectedFeatures, selectedChildCategories) => {

    let feature_ids = JSON.stringify(selectedFeatures);
    let category_ids = JSON.stringify(selectedChildCategories);
    /*  const toSend = {
        name : data.name,
        description : data.description,
        image : image,
        category_ids : category_ids,
        feature_ids : feature_ids,
        type : data.type,
    } */

    let toSend = new FormData();

    toSend.append("name", data.name);
    toSend.append("description", data.description);
    toSend.append("delete_image", deleteImage);
    if(eImage != null){
        toSend.append("image", eImage.file);
    }
    toSend.append("category_ids", category_ids);
    toSend.append("feature_ids", feature_ids);
    toSend.append("type", data.type);

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/category/${categoryId}`, toSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getCategoriesTypePaginated(type, id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate?page=${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .post(`/get/features/type/${type}/paginate`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function deleteCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function addCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/add/category/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getSelectedCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/selected/categories')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteSelectedCategeory(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/category/${id}/selected`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}