import axios from "axios";

export const createFeature = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/create/feature`, data)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const updateFeature = (data, id) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/feature/${id}`, data)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const getFeatures = () => {

    return new Promise((resolve, reject) => {
        axios
            .get(`/get/features/paginate`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function getPaginatedFeatures(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/features/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/features/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

};

export function deleteFeature(feature_id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/feature/${feature_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function addValue(feature_id, featureValue) {

    let formData = new FormData();
    formData.append("value", featureValue);

    return new Promise((resolve, reject) => {
        axios
            .post(`/add/feature/${feature_id}/value`, formData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function addManyValues(feature_id, featureValue) {

    let formData= {"value": featureValue};

    return new Promise((resolve, reject) => {
        axios
            .post(`/add/many/feature/${feature_id}/value`, formData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}