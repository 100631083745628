import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col
} from "reactstrap";

// core components
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import './filestyle.css';
import './videostyle.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AuthContext } from "context/AuthContext";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from "@material-ui/core/Tooltip";
import 'video-react/dist/video-react.css';
import moment from "moment";
import FormHelperText from '@material-ui/core/FormHelperText';
import {addManyValues, getFeatures, getPaginatedFeatures} from "services/FeatureServices";
import FeatureHeader from "components/Headers/FeatureHeader";
import { updateFeature } from "services/FeatureServices";
import { createFeature } from "services/FeatureServices";
import { deleteFeature } from "services/FeatureServices";
import ReactLoading from "react-loading";
import { addValue } from "services/FeatureServices";
import ReactPaginate from "react-paginate";
import {computeNumerotation} from "../../constants";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Feature = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const { ref: name, ...restName } = register("name", { required: true, minLength: 3 });
    // const { ref: type, ...restType } = register("type");
    const { ref: unit, ...restUnit } = register("unit", { required: false, minLength: 1 });


    const { user } = useContext(AuthContext);
    const [openFeature, setOpenFeature] = useState(false);
    const [openEditFeature, setOpenEditFeature] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [alertMessage, setAlertMessage] = useState("");
    const [openDetails, setOpenDetails] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddFeatureValue, setOpenAddFeatureValue] = useState(false);
    const [features, setFeatures] = useState([]);
    const [currentFeature, setCurrentFeature] = useState({
        name: "",
        type: "",
        unit: "",
    });
    const [isLoading, setLoading] = useState(true);
    const [featureValue, setFeatureValue] = useState("");

    const retrieveFeatures = async () => {
        let response = await getPaginatedFeatures();
        setFeatures(response.data);
    }

    const handleFeature = () => {
        setOpenFeature(!openFeature);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setCurrentFeature((prevState) => ({
            ...prevState,
            name: "",
            type: "",
            unit: "",
        }));
    };

    const handleAddFeatureValue = () => {
        setOpenAddFeatureValue(!openAddFeatureValue);
    };

    const handleEditFeature = () => {
        setOpenEditFeature(!openEditFeature);
        setOpenTable(!openTable);
        setOff(!off);
        // console.log(activeObject);
        // console.log(currentFeature);
    };

    const closeEditFeature = () => {
        setOpenEditFeature(!openEditFeature);
        setOpenTable(!openTable);
        setOff(!off);
        setCurrentFeature((prevState) => ({
            ...prevState,
            name: "",
            type: "",
            unit: "",
        }));
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDetails = () => {
        setOpenDetails(!openDetails);
    };

    const handleChangeFeatureValue = (e) => {
        setFeatureValue(e.target.value);
    };

    const handleChangeInput = (e) => {
        setCurrentFeature((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const saveFeature = async (data) => {

        setSubmited(true);

        try {

            await createFeature(data);

            setOpenFeature(!openFeature);
            setOpenTable(!openTable);
            setOff(!off);

            retrieveFeatures();

            setAlertMessage("Feature created sucessfully");
            setAlertType("success");
            handleOpenAlert();
            reset();
            setCurrentFeature((prevState) => ({
                ...prevState,
                name: "",
                type: "",
                unit: "",
            }));
            setSubmited(false);
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setCurrentFeature((prevState) => ({
                ...prevState,
                name: "",
                type: "",
                unit: "",
            }));
            setSubmited(false);
        }
    }

    const editFeature = async (data) => {

        setSubmited(true);

        let feature_id = activeObject.item.id;

        try {
            await updateFeature(data, feature_id);

            setOpenEditFeature(!openEditFeature);
            setOpenTable(!openTable);
            setOff(!off);
            retrieveFeatures();

            setAlertMessage("Feature updated sucessfully");
            setAlertType("success");
            handleOpenAlert();
            reset();
            setCurrentFeature((prevState) => ({
                ...prevState,
                name: "",
                type: "",
                unit: "",
            }));
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setCurrentFeature((prevState) => ({
                ...prevState,
                name: "",
                type: "",
                unit: "",
            }));
            setSubmited(false);
        }
    }

    const addFeatureValue = async () => {
        setOpenAddFeatureValue(false);
        let feature_id = activeObject.item.id;

        try {
            await addValue(feature_id, featureValue);

            setAlertMessage("Feature value added sucessfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveFeatures();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const addManyFeatureValue = async () => {
        let trimArray = [];

        setOpenAddFeatureValue(false);
        let feature_id = activeObject.item.id;
        let tab = featureValue.split(',');

        tab.forEach(myFunction);

        function myFunction(item) {
            if(item !== '')
                trimArray.push(item);
        }

       //  console.log("les donness")
       //  console.log(trimArray)
       // return
        try {
            await addManyValues(feature_id, trimArray);

            setAlertMessage("Feature value added sucessfully");
            setAlertType("success");
            handleOpenAlert();

            await retrieveFeatures();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const delFeature = async () => {
        setOpenDeleteModal(false);
        let feature_id = activeObject.item.id;

        try {
            await deleteFeature(feature_id);

            setAlertMessage("Feature deleted sucessfully");
            setAlertType("success");
            handleOpenAlert();

           await retrieveFeatures();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const retrieveFeaturesPaginated = async (page) => {
        let response = await getPaginatedFeatures(page, itemPerPage);
        setFeatures(response.data);
        setCurrentPage(response.meta.current_page);
    }

    const handlePageClick = (data) => {
        const requestedPage = data.selected + 1;
        retrieveFeaturesPaginated(requestedPage);
    }
    const handleSearch = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        let response = await getPaginatedFeatures(undefined, parseInt(nb));
        setFeatures(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    }

    useEffect(() => {
        const retrieveFeatures = async () => {
            let response = await getPaginatedFeatures();
            setLastPage(response.meta.last_page);
            setCurrentPage(response.meta.current_page);
            setFeatures(response.data);
            setLoading(false);
        }
        retrieveFeatures();
    }, []);

    const DetailModal = ({ object: { item } }) => (
        <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Feature details</DialogTitle>
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <Table className="align-items-center table-flush" responsive>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>Nom</td>
                                    <td> {item.name}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Type</td>
                                    <td> {item.type}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Unit</td>
                                    <td> {item.unit} </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <span>Created at </span></td>
                                    <td>  {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                    </td>
                                </tr>

                            </tbody>
                        </Table>
                        <h2>Feature Values</h2>
                        <br />
                        <ul>
                            {item.feature_values?.map((elt) =>
                                <li key={elt.id}> {elt.value}</li>)}
                        </ul>
                    </Row>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetails} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Dialog open={openDeleteModal} onClose={handleDeleteModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Delete Feature</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this feature ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={delFeature}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openAddFeatureValue} onClose={handleAddFeatureValue} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Add values for feature {activeObject?.item.name}</DialogTitle>
                    <DialogContent>
                        <Input
                            className="form-control-alternative"
                            placeholder="Value here ..."
                            onChange={e => handleChangeFeatureValue(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddFeatureValue} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            // onClick={addFeatureValue}
                            onClick={addManyFeatureValue}
                            color="primary"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>


            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <FeatureHeader handleFeature={handleFeature} off={off} />
            {/* Page content */}

            {openFeature ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Create a new feature</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleFeature}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit(saveFeature)}>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup error={errors.name ? true : undefined}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Name <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder="Name here ..."
                                                        {...restName}
                                                        innerRef={name}
                                                        aria-invalid={errors.name ? "true" : "false"}
                                                    />
                                                    {errors.name &&
                                                        <FormHelperText style={{ color: 'red' }}>At least 5 characters are
                                                            required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/*<div className="pl-lg-4">*/}
                                    {/*    <Row>*/}
                                    {/*        <Col md="12">*/}
                                    {/*            <FormGroup error={errors.type ? true : undefined}>*/}
                                    {/*                <label*/}
                                    {/*                    className="form-control-label"*/}
                                    {/*                    htmlFor="input-address"*/}
                                    {/*                >*/}
                                    {/*                    Type <span style={{ color: 'red' }}>*</span>*/}
                                    {/*                </label>*/}
                                    {/*                <Input*/}
                                    {/*                    className="form-control-alternative"*/}
                                    {/*                    type="select"*/}
                                    {/*                    {...restType}*/}
                                    {/*                    innerRef={type}*/}
                                    {/*                    aria-invalid={errors.type ? "true" : "false"}>*/}
                                    {/*                    <option value="NUMBER">NUMBER</option>*/}
                                    {/*                    <option value="STRING">STRING</option>*/}
                                    {/*                    <option value="CARACTER">CARACTER</option>*/}
                                    {/*                    <option value="BOOLEAN">BOOLEAN</option>*/}
                                    {/*                    <option value="SET">SET</option>*/}
                                    {/*                </Input>*/}
                                    {/*            </FormGroup>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*</div>*/}
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup error={errors.name ? true : undefined}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Unit
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder="Name here ..."
                                                        {...restUnit}
                                                        innerRef={unit}
                                                        aria-invalid={errors.unit ? "true" : "false"}
                                                    />
                                                    {errors.unit &&
                                                        <FormHelperText style={{ color: 'red' }}>At least 5 characters are
                                                            required !</FormHelperText>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="pl-lg-4">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                {/* <h3 className="mb-0"></h3> */}
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                {submited ?
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        size="sm"
                                                        disabled
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        size="sm"
                                                    >
                                                        Submit
                                                    </Button>
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}

            {openEditFeature ?
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Edit Feature Information</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="danger"
                                                onClick={closeEditFeature}
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(editFeature)}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Name
                                                        </label>
                                                        <Input
                                                            name="name"
                                                            value={currentFeature.name}
                                                            className="form-control-alternative"
                                                            {...restName}
                                                            innerRef={name}
                                                            onChange={handleChangeInput}
                                                        />
                                                        {errors.name &&
                                                            <FormHelperText style={{ color: 'red' }}>At least 5 characters are required !</FormHelperText>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/*<div className="pl-lg-4">*/}
                                        {/*    <Row>*/}
                                        {/*        <Col md="12">*/}
                                        {/*            <FormGroup error={errors.type ? true : undefined}>*/}
                                        {/*                <label*/}
                                        {/*                    className="form-control-label"*/}
                                        {/*                >*/}
                                        {/*                    Type <span style={{ color: 'red' }}>*</span>*/}
                                        {/*                </label>*/}
                                        {/*                <Input*/}
                                        {/*                    className="form-control-alternative"*/}
                                        {/*                    type="select"*/}
                                        {/*                    name="type"*/}
                                        {/*                    {...restType}*/}
                                        {/*                    innerRef={type}*/}
                                        {/*                    aria-invalid={errors.type ? "true" : "false"}*/}
                                        {/*                    onChange={handleChangeInput}>*/}
                                        {/*                    <option value="NUMBER" selected={currentFeature.type == "NUMBER"}>NUMBER</option>*/}
                                        {/*                    <option value="STRING" selected={currentFeature.type == "STRING"}>STRING</option>*/}
                                        {/*                    <option value="CARACTER" selected={currentFeature.type == "CARACTER"}>CARACTER</option>*/}
                                        {/*                    <option value="BOOLEAN" selected={currentFeature.type == "BOOLEAN"}>BOOLEAN</option>*/}
                                        {/*                    <option value="SET" selected={currentFeature.type == "SET"}>SET</option>*/}
                                        {/*                </Input>*/}
                                        {/*            </FormGroup>*/}
                                        {/*        </Col>*/}
                                        {/*    </Row>*/}
                                        {/*</div>*/}
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Unit
                                                        </label>
                                                        <Input
                                                            name="unit"
                                                            value={currentFeature.unit}
                                                            className="form-control-alternative"
                                                            {...restUnit}
                                                            innerRef={unit}
                                                            onChange={handleChangeInput}
                                                        />
                                                        {errors.unit &&
                                                            <FormHelperText style={{ color: 'red' }}>At least 5 characters are required !</FormHelperText>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="pl-lg-4">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    {/* <h3 className="mb-0"></h3> */}
                                                </Col>
                                                <Col className="text-right" xs="4">
                                                    {submited ?
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                            disabled
                                                        >
                                                            Submit
                                                        </Button>
                                                        :
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                : ""}


            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Your Features Here </h3>
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spin"} color="yellow" /> :
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            {/*<th scope="col">Type</th>*/}
                                            <th scope="col">Unit</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            features?.map((item, index) =>
                                                <tr key={item.id}>
                                                    <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                                    </span>
                                                    </th>
                                                    <th scope="row">
                                                        <Media className="align-items-center">
                                                            <Media>
                                                                <span className="mb-0 text-sm">
                                                                    {item.name}
                                                                </span>
                                                            </Media>
                                                        </Media>
                                                    </th>
                                                    {/*<td>{item.type}</td>*/}
                                                    <td>
                                                        {item.unit}
                                                    </td>
                                                    <td>
                                                        {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">
                                                            <Tooltip title="Add value" placement="top">
                                                                <span
                                                                    className="material-icons"
                                                                    onClick={() => {
                                                                        setActiveObject({item});
                                                                        handleAddFeatureValue();
                                                                       // console.log(item);
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'green' }}>add
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="Edit" placement="top">
                                                                <span
                                                                    className="material-icons"
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        setCurrentFeature((prevState) => ({
                                                                            ...prevState,
                                                                            name: item.name,
                                                                            type: item.type,
                                                                            unit: item.unit,
                                                                        }));
                                                                        handleEditFeature();
                                                                        //console.log(activeObject);
                                                                        //console.log(currentFeature);
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'orange' }}>edit
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" placement="top">
                                                                <span
                                                                    className='material-icons'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleDeleteModal();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'firebrick' }}>
                                                                    delete
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="View Details" placement="top">
                                                                <span
                                                                    className='material-icons danger'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleDetails();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'dodgerblue' }}>
                                                                    visibility
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>}
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
            {openDetails ? <DetailModal object={activeObject} /> : null}
        </>
    );
};

export default Feature;
