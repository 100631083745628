import axios from "axios";

export function searchWithIndicator(indicator) {
    return new Promise((resolve, reject) => {
        axios
            .get('/search-admin/'+`${indicator}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function getAllUsers() {
    return new Promise((resolve, reject) => {
        axios
            .get('/users')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}


export function getAll() {
    return new Promise((resolve, reject) => {
        axios
            .get('/admins')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function getOne(id) {
    return new Promise((resolve, reject) => {
        axios
            .get('/admins/'+`${id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function getPaginatedAdmins(page, nb_of_element) {
    let formData = new FormData();
    formData.append("page", page);
    formData.append("numberElement", nb_of_element);

    return new Promise((resolve, reject) => {
        axios
            .post('/paginate/admins', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

// export function getPaginatedAdmins(id) {
//    if(id !== undefined){
//     return new Promise((resolve, reject) => {
//         axios
//             .get('/paginate/admins?page='+`${id}`)
//             .then( response => {
//                 resolve(response.data.data);
//             })
//             .catch(error => {
//                 reject(error.response.data);
//             });
//     }); }else {
//        return new Promise((resolve, reject) => {
//            axios
//                .get('/paginate/admins')
//                .then( response => {
//                    resolve(response.data.data);
//                })
//                .catch(error => {
//                    reject(error.response.data);
//                });
//        });
//     }
//
// };

export function getPaginatedUsersWithRange(nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);

    return new Promise((resolve, reject) => {
        axios
            .post('/paginate/users/range', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function getPaginatedUsers(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
   if(id !== undefined){
    return new Promise((resolve, reject) => {
        axios
            .post('/paginate/users?page='+`${id}`, data)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    }); }else {
       return new Promise((resolve, reject) => {
           axios
               .post('/paginate/users', data)
               .then( response => {
                   resolve(response.data.data);
               })
               .catch(error => {
                   reject(error.response.data);
               });
       });
    }

};

export function deleteAdmin(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete('/admin/'+`${id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function bannishAdmin(id) {
    return new Promise((resolve, reject) => {
        axios
            .put('/status/admin/'+`${id}`)
            .then( response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function updateAdminInfos(id, data) {

    return new Promise((resolve, reject) => {
        axios
            .put('/admin/'+`${id}`, data)
            .then( response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function adminDetails(id) {
    return new Promise((resolve, reject) => {
        axios
            .get('/admins/'+`${id}`+'/details')
            .then( response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function updateAdminPassword(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put('/admin/'+`${id}`+'/password', data)
            .then( response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export function updateAvatar(id, image) {
    console.log("service");
    let formData = new FormData();
    formData.append("avatar", image);

    return new Promise((resolve, reject) => {
        axios
            .post('/admins/'+`${id}`+'/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function removeAvatarService(id, image) {

    return new Promise((resolve, reject) => {
        axios
            .put('/admins/'+`${id}`+'/avatar/remove', image)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};


export const resendMailAdmin = (e) => {

    const email = e.target.getAttribute("value");

    console.log(email);

    let registerFormData = new FormData();

    registerFormData.append("email", email);

    return new Promise((resolve, reject) => {
        axios
            .post('/resend/mail', registerFormData)
            .then( response => {
                resolve(response.data.message);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}


export const createAdmin = (data) => {

  console.log(data);

  let registerFormData = new FormData();

  registerFormData.append("email", data.email);

  return new Promise((resolve, reject) => {
    axios
        .post('/create/admin', registerFormData)
        .then( response => {
             resolve(response.data.data.user);
        })
        .catch(error => {
          reject(error.response.data);
        });
  });
};

export const overview = () => {

    return new Promise((resolve, reject) => {
        axios
            .get('/admin/overview')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const getFaqs = () => {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/faqs/type/FAQ')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const createFaq = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/create/faq`, data)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });};

export const updateFaq = (id, data) => {

        return new Promise((resolve, reject) => {
            axios
                .put('/update/faq/'+`${id}`, data)
                .then( response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

export function deleteFaq(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete('/delete/faq/'+`${id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}

export const getAgbs = () => {

    return new Promise((resolve, reject) => {
        axios
            .get('/get/faqs/type/AGB')
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const createAgb = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/create/agb`, data)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });};

export const updateAgb = (id, data) => {

        return new Promise((resolve, reject) => {
            axios
                .put('/update/agb/'+`${id}`, data)
                .then( response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

export function deleteAgb(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete('/delete/agb/'+`${id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}