import Index from "views/Index.js";
import Articles from "views/examples/Articles.js";
import Category from "views/examples/Category";
import ManageUsers from "./views/examples/user/ManageUsers";
import Profile from "./views/examples/user/Profile";
import Purchases from "views/examples/Purchases";
import Feature from "./views/examples/Feature";
import SelectedModels from "views/examples/SelectedModels";

const admin_routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-world text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/manage-users",
    name: "Manage Users",
    icon: "ni ni-user-run text-green",
    component: ManageUsers,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/manage-admin",
  //   name: "Manage Administrator",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: CreateAdmin2,
  //   layout: "/admin",
  // },
  {
    path: "/manage-features",
    name: "Features",
    icon: "ni ni-settings-gear-65 text-blue",
    component: Feature,
    layout: "/admin",
  },
  {
    path: "/manage-categories",
    name: "Categories",
    icon: "ni ni-shop text-blue",
    component: Category,
    layout: "/admin",
  },
  {
    path: "/manage-articles",
    name: "Articles",
    icon: "ni ni-bag-17 text-red",
    component: Articles,
    layout: "/admin",
  },
  // {
  //   path: "/manage-vehicles",
  //   name: "Command lists",
  //   icon: "ni ni-bullet-list-67 text-green",
  //   component: Vehicles,
  //   layout: "/admin",
  // },{
  //   path: "/manage-vehicles",
  //   name: "payment lists",
  //   icon: "ni ni-bullet-list-67 text-green",
  //   component: Vehicles,
  //   layout: "/admin",
  // },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/all-purchases",
    name: "All Purchaces",
    icon: "ni ni-cart text-yellow",
    component: Purchases,
    layout: "/admin",
  },
  {
    path: "/selected-models",
    name: "Selected Model",
    icon: "ni ni-single-02 text-yellow",
    component: SelectedModels,
    layout: "/admin",
  },
   /* {
    path: "/manage-chats",
    name: "My Chats",
    icon: "ni ni-single-02 text-yellow",
    component: MyChats,
    layout: "/admin",
  },*/
  // {
  //   path: "/manage-preview",
  //   name: "My views",
  //   icon: "ni ni-single-02 text-yellow",
  //   layout: "/admin",
  //   component: Markdown,
  // },
  // {
  //   path: "/users/details",
  //   name: "UserSummary",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: UserSummary,
  //   layout: "/admin",
  // },
  // {
  //   path: "/users/purchase/details",
  //   name: "Purchase",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: PurchaseUserDetails,
  //   layout: "/admin",
  // },
];

export default admin_routes;
