
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";

function ProtectionAdmin(props) {

    let AdminCmp = props.Cmp;
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem("access_token") && !sessionStorage.getItem("access_token")) {
            history.push("/auth/login");
        }/*  else {
            history.push("/admin/index");
        } */

    }, []);

    return (
        <>
            <AdminCmp />
        </>
    );

}

export default ProtectionAdmin;

