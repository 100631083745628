import { Button, Container, Row, Col } from "reactstrap";

const UserMainHeader = ({ handleNews, handleArticle, handleVehicle, off }) => {

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "200px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/profile-cover.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              {/*{off ?*/}
              {/*  <Button*/}
              {/*    color="info"*/}
              {/*    disabled*/}
              {/*    onClick={handleNews}*/}
              {/*  >*/}
              {/*    Add User*/}
              {/*  </Button>*/}
              {/*  :*/}
              {/*  <Button*/}
              {/*    color="info"*/}
              {/*    onClick={handleNews}*/}
              {/*  >*/}
              {/*    Add User*/}
              {/*  </Button>*/}
              {/*}*/}
              {/*{off ?*/}
              {/*  <Button*/}
              {/*    color="warning"*/}
              {/*    disabled*/}
              {/*    onClick={handleArticle}*/}
              {/*  >*/}
              {/*    Add Article*/}
              {/*  </Button>*/}
              {/*  :*/}
              {/*  <Button*/}
              {/*    color="warning"*/}
              {/*    onClick={handleArticle}*/}
              {/*  >*/}
              {/*    Add Article*/}
              {/*  </Button>*/}
              {/*}*/}
              {/*{off ?*/}
              {/*  <Button*/}
              {/*    color="secondary"*/}
              {/*    disabled*/}
              {/*    onClick={handleVehicle}*/}
              {/*  >*/}
              {/*    Add Vehicule*/}
              {/*  </Button>*/}
              {/*  :*/}
              {/*  <Button*/}
              {/*    color="secondary"*/}
              {/*    onClick={handleVehicle}*/}
              {/*  >*/}
              {/*    Add Vehicule*/}
              {/*  </Button>*/}
              {/*}*/}
              {/* <Link to="/admin/article-form" className="btn btn-primary">Sign up</Link> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserMainHeader;
