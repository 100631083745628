
//import { CLIENT_URL } from "../../constants";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import env from "ts-react-dotenv";

const MEDIA_URL = env.REACT_APP_MEDIA_URL;
const CLIENT_URL = env.REACT_APP_CLIENT_URL;

const Footer = () => {

  let location = useLocation();
  const { pathname } = useLocation();
  // ...
  if (pathname === "/admin/invoice" ) {
    return <></>;
  }

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1"
              href={CLIENT_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              Dodo-Shop CLIENT
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="/admin/plican-team"
                rel="noopener noreferrer"
              >
                Dodo-Shop  Team
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="/admin/about-us"
                rel="noopener noreferrer"
              >
                About Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="/admin/f-a-q"
                rel="noopener noreferrer"
              >
                FAQ
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="/admin/agb"
                rel="noopener noreferrer"
              >
                AGB
              </NavLink>
            </NavItem>

          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
