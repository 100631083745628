/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {Bar} from "react-chartjs-2";
// core components

// data: {
//   labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
//       datasets: [{
//     data: [86,114,106,106,107,111,133],
//     label: "Total",
//     borderColor: "rgb(62,149,205)",
//     backgroundColor: "rgb(62,149,205,0.1)",
//     borderWidth:2
//   }, {
//     data: [70,90,44,60,83,90,100],
//     label: "Accepted",
//     borderColor: "rgb(60,186,159)",
//     backgroundColor: "rgb(60,186,159,0.1)",
//     borderWidth:2
//   }, {
//     data: [10,21,60,44,17,21,17],
//     label: "Pending",
//     borderColor: "rgb(255,165,0)",
//     backgroundColor:"rgb(255,165,0,0.1)",
//     borderWidth:2
//   }, {
//     data: [6,3,2,2,7,0,16],
//     label: "Rejected",
//     borderColor: "rgb(196,88,80)",
//     backgroundColor:"rgb(196,88,80,0.1)",
//     borderWidth:2
//   }
//   ]
// }

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];



const Vertical = (props) => {
   let data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: props?.plotData,
        //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      // {
      //   label: 'Dataset 2',
      //   data: [70,90,44,60,83,90,100],
      //   //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  const [startDate, setStartDate] = useState(new Date());
  console.log("all data")
  console.log(props)
  return (
    <>
      {/* Page content */}
      {/*<DatePicker*/}
      {/*  selected={startDate}*/}
      {/*  dateFormat="yyyy"*/}
      {/*  //showMonthYearPicker*/}
      {/*  showYearPicker*/}
      {/*  onChange={(date) => setStartDate(date)} />*/}

      <Container mt-5 fluid>

        <Bar options={options} data={data} />
      </Container>
    </>
  );
};

export default Vertical;
