
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Progress,
} from "reactstrap";

// core components
import ArticleHeader from "components/Headers/ArticleHeader.js";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import './filestyle.css';
import './videostyle.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {AuthContext} from "context/AuthContext";
import {createArticle, deleteArticle, getPaginatedArticle, getPostsTypePaginated} from "services/ArticleServices";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ImageComponent from "./ImageComponent";
import {getPostsType} from "services/ArticleServices";
import ReactPaginate from "react-paginate";
import moment from "moment";
//import { CLIENT_URL, hastagHighlight, inputStyle } from "../../constants";
import {v4 as uuidv4} from "uuid";
import {Player} from "video-react";
import {activateArticle} from "services/ArticleServices";
//import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import FormHelperText from '@material-ui/core/FormHelperText';
import {marked} from "marked";
import Picker from "emoji-picker-react";
import {getCategories} from "services/CategoryServices";
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {getArticles} from "services/ArticleServices";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {addValue} from "services/FeatureServices";
import {updateArticle} from "services/ArticleServices";
import {updateVariant} from "services/ArticleServices";
import {getDescentCategories} from "services/CategoryServices";
import {addNewVariant} from "services/ArticleServices";
import env from "ts-react-dotenv";
import {computeNumerotation, computePrice, hastagHighlight, inputStyle} from "../../constants";
import { addArticle } from "services/ArticleServices";
import ReactLoading from "react-loading";

const steps = ['Product Detail', 'Product variant details'];

const MEDIA_URL = env.MEDIA_URL;
const CLIENT_URL = env.CLIENT_URL;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selectedFeatures, theme) {
    return {
        fontWeight:
            selectedFeatures.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Articles = () => {

    const {
        register: register,
        handleSubmit,
        reset, formState: {errors}
    } = useForm({
        defaultValues: {
            tax: 19,
            price:0,
            reduction_price:0,
            reduction_type: ''
        }
    });

    const theme = useTheme();

    const {ref: name, ...restName} = register("name", {required: true, minLength: 5});
    //const {ref: description, ...restDescription} = register("description", {required: true, minLength: 10});
    const {ref: price, ...restPrice} = register("price", {required: true, min: 0});
    const {ref: reduction_price, ...restReductionPrice} = register("reduction_price", {min: 0});
    const {ref: tax, ...restTax} = register("tax", {min: 0});
    const {ref: association, ...restAssociation} = register("association");
    const {ref: reduction_type, ...restReductionType} = register("reduction_type");

    const {user} = useContext(AuthContext);
    const [openArticle, setOpenArticle] = useState(false);
    const [openAddVariant, setOpenAddVariant] = useState(false);
    const [openEditArticle, setOpenEditArticle] = useState(false);
    const [openEditVariant, setOpenEditVariant] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [openVariantTable, setOpenVariantTable] = useState(false);
    const [off, setOff] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [articles, setArticles] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    const [openDescriptions, setOpenDescriptions] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openVariantModal, setOpenVariantModal] = useState(false);
    const [images, setImages] = useState([]);
    const [variantImages, setVariantImages] = useState([]);
    const [savedImages, setSavedImages] = useState([]);
    const [savedVariantImages, setSavedVariantImages] = useState([]);
    const [toDeleteImages, setToDeleteImages] = useState([]);
    const [toDeleteVariantImages, setToDeleteVariantImages] = useState([]);
    const inputRef = useRef(null);
    const variantRef = useRef(null);
    const videoRef = useRef(null);
    const [videos, setVideos] = useState([]);
    const [savedVideos, setSavedVideos] = useState([]);
    const [toDeleteVideos, setToDeleteVideos] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [progress, setProgress] = useState(0);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [markdown, setMarkdown] = useState('');
    const [categories, setCategories] = useState([]);
    const [variantCats, setVariantCats] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [isLoading, setLoading] = useState(true);
    const [currentArticle, setCurrentArticle] = useState({
        name: "",
        price: 0,
        reduction_price: 0,
        reduction_type: "",
        tax: 0,
        association: "",
        description: "",
        categories: {},
        variants: {},
    });
    const [currentVariant, setCurrentVariant] = useState({
        id: 0,
        quantity: 0,
        price: 0,
        reduction_price: 0,
        reduction_type: "",
        feature_values: {},
    });
    const [featuresToLoad, setFeaturesToLoad] = useState([]);
    const [inputs, setInputs] = useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [featuresValues, setFeaturesValues] = useState([]);
    const [variantPrice, setVariantPrice] = useState(0);
    const [variantQuantity, setVariantQuantity] = useState(0);
    const [variants, setVariants] = useState([]);
    const [variantReductionPrice, setVariantReductionPrice] = useState(0);
    const [variantReductionType, setVariantReductionType] = useState('');
    const [markdownUpdated, setMarkdownUpdated] = useState('');

     const clearVariant = () =>{
        setVariantReductionPrice(0);
        setVariantReductionType("");
        setCurrentVariant((prevState) => ({
            ...prevState,
            id: 0,
            price: 0,
            quantity: 0,
            reduction_price: 0,
            reduction_type: "",
            feature_values: {},
        }));
    }

   const clearArticle = () =>{
        setCurrentArticle((prevState) => ({
            ...prevState,
            name: "",
            price: 0,
            tax: 0,
            reduction_price: 0,
            reduction_type: "",
            association: "",
            description: "",
            categories: {},
            variants: {},
        }));
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if(selectedCategories.length < 1){
            setAlertMessage("You have to select at least one category");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        if(images.length < 1){
            setAlertMessage("You have to select at least one image");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        console.log("article")
        console.log(currentArticle);

        const currentPrice =  computePrice(currentArticle);

        if(currentArticle.price <= 0){
            setAlertMessage("Invalid price");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        if(currentPrice <= 0){
            setAlertMessage("Impossible to make this discount.");
            setAlertType("error");
            handleOpenAlert();
            return;
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        console.log("article")
        console.log(currentArticle);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleSelectCategory = (event) => {
        setSelectedCategories(event.target.value);
        fetchFeatures(event.target.value);
    };

    const fetchFeatures = async (selectedCats) => {
        const myCategories = categories.filter((cat) => {
            return selectedCats.some((id) => {
                return id === cat.id;
            });
        });

        let myFeatures = [];
        for (let category of myCategories) {
            let cats = await getDescentCategories(category.id);
            for (let cat of cats) {
                const feats = cat.features;
                for (let i = 0; i < feats.length; i++) {
                    myFeatures.push({...feats[i]});
                }
            }

        }

        /*         let myFeatures = [];
                for (let category of myCategories) {
                    const feats = category.features;
                    for (let i = 0; i < feats.length; i++) {
                        myFeatures.push({ ...feats[i] });
                    }
                } */

        const ids = myFeatures.map(obj => obj.id);
        const uniqueFeatures = myFeatures.filter(({id}, index) => !ids.includes(id, index + 1));

        let featureArray = [];

        for (var i in uniqueFeatures) {
            var item = uniqueFeatures[i];
            featureArray.push(item);
        }
        setFeaturesToLoad(featureArray);
    };

    let handleChangeFeature = (i, e) => {
        let newFeatures = [...featuresToLoad];
        newFeatures[i]['value'] = e.target.value;
        setFeaturesToLoad(newFeatures);
    }

    let addFeature = (i, e) => {
        let newFeatures = [...featuresToLoad];
        newFeatures[i]['value'] = e.target.value;
        setFeaturesToLoad(newFeatures);
    }

    const addFeaturesValue = async (i, feature_id) => {
        let val = inputs[i];

        try {
            let featVal = await addValue(feature_id, val);
            setAlertMessage("Feature value added sucessfully");
            setAlertType("success");
            handleOpenAlert();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const removeVariant = (itemKey) =>{
        let tabVariants = variants.filter((e) => e.variant_key !== itemKey);
        setVariants(tabVariants);
        setAlertMessage("Variant removed successfully");
        setAlertType("success");
    }

    const addVariant = () => {

        let bool = false;
        for (let variant of variants) {
            const feats = variant.variant_feature_values;
            if (feats === featuresValues) {
                bool = true;
            }
        }

        if (variantQuantity < 1) {
            setAlertMessage("The quantity must be positive");
            setAlertType("error");
            handleOpenAlert();
        } else if (variantPrice < 1) {
            setAlertMessage("The price must be positive");
            setAlertType("error");
            handleOpenAlert();
        } else if (bool) {
            setAlertMessage("This combination already exist !");
            setAlertType("error");
            handleOpenAlert();
        } else {
            if(featuresValues.length < 1) {
                setAlertMessage("Select features of this variant");
                setAlertType("error");
                handleOpenAlert();
                return;
            }
            let key = uuidv4();
            setVariants([...variants, {
                variant_key: key,
                variant_quantity: variantQuantity,
                variant_price: variantPrice,
                variant_images: variantImages,
                variant_feature_values: featuresValues,
                variant_reduction_price: variantReductionPrice,
                variant_reduction_type:variantReductionType
            }]);
            setVariantPrice(currentArticle.price);
            setVariantReductionPrice(currentArticle.reduction_price)
            setVariantReductionType(currentVariant.reduction_type);
            setVariantQuantity(0);
            setVariantImages([]);
        }
    }

    let onFeatureChanged = (e, feature_id, feature_value_id, value) => {
        let valueExist = false;
        const newVals = featuresValues.map(obj => {
            if (obj.feature_id === feature_id) {
                valueExist = true;
                return {"feature_id": feature_id, "feature_value_id": feature_value_id, "value": value}
            }
            return obj;
        });

        if (valueExist) {
            setFeaturesValues(newVals);
        } else {
            setFeaturesValues([...featuresValues, {
                "feature_id": feature_id,
                "feature_value_id": feature_value_id,
                "value": value
            }]);
        }

       // console.log(featuresValues);
    }

    const updateProgress = (val) => {
        setProgress(val);
    };

    const handleArticle = () => {
        setOpenArticle(!openArticle);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setImages([]);
        setVideos([]);
        setVariants([]);
        setSelectedCategories([]);
        setFeaturesToLoad([]);
        setFeaturesValues([]);
        setActiveStep(0);
        clearArticle();
    };

    const handleAddVariant = () => {
        selectCategoryFeatures(variantCats);
        setOpenAddVariant(!openAddVariant);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setVariants([]);
        setVariantImages([]);
    };

    const selectCategoryFeatures = async (myCategories) => {

        //let myCategories = variantCats;
       // console.log('in select category ', currentArticle.categories);
        let myFeatures = [];
        for (let category of myCategories) {
            let cats = await getDescentCategories(category.id);
            for (let cat of cats) {
                const feats = cat.features;
                for (let i = 0; i < feats.length; i++) {
                    myFeatures.push({...feats[i]});
                }
            }
        }

        const ids = myFeatures.map(obj => obj.id);
        const uniqueFeatures = myFeatures.filter(({id}, index) => !ids.includes(id, index + 1));

        let featureArray = [];

        for (var i in uniqueFeatures) {
            var item = uniqueFeatures[i];
            featureArray.push(item);
        }
        setFeaturesToLoad(featureArray);
    }

    const closeAddVariant = () => {
        setOpenAddVariant(!openAddVariant);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setVariants([]);
        setVariantImages([]);
        setFeaturesToLoad([]);
        setFeaturesValues([]);
    };

    const handleEditArticle = () => {
        setOpenEditArticle(!openEditArticle);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleEditVariant = () => {
        setOpenEditVariant(!openEditVariant);
        setOpenVariantTable(!openVariantTable);
    };

    const closeEditArticle = () => {
        setOpenEditArticle(!openEditArticle);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setImages([]);
        setToDeleteImages([]);
        setSelectedCategories([]);
        setFeaturesToLoad([]);
        clearArticle();
    };

    const closeEditVariant = () => {
        setOpenEditVariant(!openEditVariant);
        setOpenVariantTable(!openVariantTable);
        setVariantImages([]);
        setCurrentVariant((prevState) => ({
            id: 0,
            quantity: 0,
            price: 0,
            reduction_price: 0,
            reduction_type: "",
            feature_values: {},
        }));
    };

    const handleVariants = () => {
        setOpenVariantTable(!openVariantTable);
        setOpenTable(!openTable);
        setOff(!off);
    };

    const handleDescription = () => {
        setOpenDescriptions(!openDescriptions)
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleClickImageInput = () => {
        inputRef.current.click();
    }

    const handleUploadImage = (event) => {
        var image = event.target.files[0];
        var fSize = Math.round((image.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("Image size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileID = uuidv4() + "." + image.name.split('.').pop();
            let imageUrl = URL.createObjectURL(image);
            setImages([...images, {key: fileID, url: imageUrl, file: image}]);
        }
        event.target.value = null
    }

    const handleRemoveImage = (imageKey) => {
        const myImages = images.filter(element => element.key !== imageKey);
        setImages(myImages);
    }

    const removeToDeleteImage = (imageId) => {
        const myImages = savedImages.filter(element => element.id !== imageId);
        setSavedImages(myImages);
        setToDeleteImages([...toDeleteImages, imageId]);
    }

    const handleClickVariantImageInput = () => {
        variantRef.current.click();
    }

    const handleUploadVariant = (event) => {
        let image = event.target.files[0];
        let fSize = Math.round((image.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("Image size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileID = uuidv4() + "." + image.name.split('.').pop();
            let imageUrl = URL.createObjectURL(image);
            setVariantImages([...variantImages, {key: fileID, url: imageUrl, file: image}]);
        }
        event.target.value = null
    }

    const handleRemoveVariantImage = (imageKey) => {
        const myImages = images.filter(element => element.key !== imageKey);
        setVariantImages(myImages);
    }

    const removeToDeleteVariantImage = (imageId) => {
        const myImages = savedVariantImages.filter(element => element.id !== imageId);
        setSavedVariantImages(myImages);
        setToDeleteVariantImages([...toDeleteVariantImages, imageId]);
    }

    const handleChangeVariantQuantity = (event) => {
        setVariantQuantity(event.target.value);
    };

    const handleChangeVariantPrice = (event) => {
        //const price = event.target.value;
       // console.log("le prix du variant  "+price)
        setVariantPrice(event.target.value);
    };

    const handleDetails = () => {
        setOpenDetails(!openDetails);
    };

    const handleVariantModal = () => {
        setOpenVariantModal(!openVariantModal);
    };

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleChangeInput = (e) => {
        console.log(currentArticle);
        setCurrentArticle((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

        if(e.target.name=== 'price'){
            setVariantPrice(e.target.value);
        }

        if(e.target.name=== 'reduction_price'){
            setVariantReductionPrice(e.target.value);
        }

    };

    const handleChangeVariant = (e) => {
        setCurrentVariant((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

        if(e.target.name === 'reduction_type'){
            setVariantReductionType(e.target.value);
        }

        // if(e.target.name=== 'price'){
        //     setVariantPrice(e.target.value);

        //}
    };

    const addVariants = async () => {

        let article_id = activeObject.item.id;

        try {
            await addNewVariant(article_id, variants, updateProgress);
            setOpenAddVariant(!openAddVariant);
            setOpenTable(!openTable);
            setOff(!off);

            setAlertMessage("Variant added sucessfully");
            setAlertType("success");
            handleOpenAlert();
            retrieveArticles();

            setVariants([]);
            setVariantImages([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();

            setVariants([]);
            setVariantImages([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);
        }
    }

    const saveArticle = async (data) => {
        if(variants.length < 1) {
            setAlertMessage("You have to add at least one variant ");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        const currentPrice =  computePrice(currentArticle);

        if(currentPrice <= 0){
            setAlertMessage("Impossible to make this discount.");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        setSubmited(true);

        //const articleQuantity = data.
        try {
            await createArticle(data, images, selectedCategories, featuresToLoad, variants, updateProgress, markdown);

            setOpenArticle(!openArticle);
            setOpenTable(!openTable);
            setOff(!off);

            setAlertMessage("Article created successfully");
            setAlertType("success");
            handleOpenAlert();

            await retrieveArticles();
            reset();
            setImages([]);
            setVariants([]);
            setSelectedCategories([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);
            clearVariant();
            setCurrentArticle((prevState) => ({
                ...prevState,
                name: "",
                price: 0,
                tax: 0,
                reduction_price: 0,
                reduction_type: "",
                association: "",
                description: "",
                categories: {},
                variants: {},
            }));

            setActiveStep(0);
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setImages([]);
            setVariants([]);
            setSelectedCategories([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);
            clearVariant();
            setCurrentArticle((prevState) => ({
                ...prevState,
                name: "",
                tax: 0,
                price: 0,
                reduction_price: 0,
                reduction_type: "",
                association: "",
                description: "",
                categories: {},
                variants: {},
            }));
            setActiveStep(0);
            setSubmited(false);
        }
    }

    const editArticle = async (data) => {

        if(images.length < 1 && savedImages.length < 1){
            setAlertMessage("You have to select at least one image");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        const currentPrice =  computePrice(currentArticle);

        if(currentPrice <= 0){
            setAlertMessage("Impossible to make this discount.");
            setAlertType("error");
            handleOpenAlert();
            return;
        }

        setSubmited(true);
        let article_id = activeObject.item.id;

        try {
            await updateArticle(data, currentArticle, article_id, images, toDeleteImages, selectedCategories, featuresToLoad, updateProgress, markdownUpdated);

            setOpenEditArticle(!openEditArticle);
            setOpenTable(!openTable);
            setOff(!off);
            setAlertMessage("Article updated sucessfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveArticles();
            reset();
            setImages([]);
            setToDeleteImages([]);
            setVariants([]);
            setSelectedCategories([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);
            clearVariant();
            setCurrentArticle((prevState) => ({
                ...prevState,
                name: "",
                price: 0,
                tax: 0,
                reduction_price: 0,
                reduction_type: "",
                association: "",
                description: "",
                categories: {},
                variants: {},
            }));
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setImages([]);
            setToDeleteImages([]);
            setVariants([]);
            setSelectedCategories([]);
            setFeaturesToLoad([]);
            setFeaturesValues([]);
            clearArticle();
            clearVariant();
            setSubmited(false);
        }
    }

    const editVariant = async () => {

        try {
            let item = await updateVariant(currentVariant, variantImages, toDeleteVariantImages, variantReductionPrice, variantReductionType);

            setOpenEditVariant(!openEditVariant);
            setOpenVariantTable(!openVariantTable);
            setAlertMessage("Variant updated sucessfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveArticles();

            setCurrentArticle((prevState) => ({
                ...prevState,
                name: item.name,
                price: item.price,
                tax: item.tax,
                reduction_price: item.reduction_price,
                reduction_type: item.reduction_type,
                association: item.association,
                description: item.description,
                categories: item.categories,
                variants: item.variants,
            }));

            setVariantImages([]);
            setToDeleteVariantImages([]);

            //setCurrentVariant((prevState) => ({
            //    ...prevState,
            //    id: 0,
            //    price: 0,
            //    quantity: 0,
            //    feature_values: {},
            //}));
            clearVariant();
            setSubmited(false);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            setVariantImages([]);
            setToDeleteVariantImages([]);
            //setCurrentVariant((prevState) => ({
            //    ...prevState,
            //    id: 0,
            //    price: 0,
            //    quantity: 0,
            //    feature_values: {},
            //}));
            clearVariant();
            setSubmited(false);
        }
    }

    const deletePost = async () => {
        setOpenDeleteModal(false);
        let post_id = activeObject.item.id;

        try {
            await deleteArticle(post_id);

            setAlertMessage("Post deleted sucessfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveArticles();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const retrieveArticles = async () => {
        let data = await getArticles();
        let arr = Object.values(data);
        setArticles(arr);
    }

    const selectArticle = async (article_id) => {
        try {
            await addArticle(article_id);

            setAlertMessage("Article selected sucessfully");
            setAlertType("success");
            handleOpenAlert();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const onEmojiClick = (event, emojiObject) => {
        let input = document.getElementById("textarea");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdown(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    };

    const onUpdateEmojiClick = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdate");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdated(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const updateMarkdown = (e) => {
        let input = document.getElementById("textarea");

        setMarkdown(input.value);
        //input.value = input.value;
    };

    const retrieveCategoriesPaginated = async (page) => {
        let response = await getPaginatedArticle(page, itemPerPage);
        setArticles(response.data);
        setCurrentPage(response.meta.current_page);
        setLastPage(response.meta.last_page);
    }

    const handlePageClick = (data) => {
        const requestedPage = data.selected + 1;
        retrieveCategoriesPaginated(requestedPage);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        let response = await getPaginatedArticle(undefined, parseInt(nb));
        setArticles(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    }

    useEffect(() => {
        const retrieveArticles = async () => {
            let response = await getPaginatedArticle();
            setLastPage(response.meta.last_page);
            setCurrentPage(response.meta.current_page);
            setArticles(response.data);
            setLoading(false);
        }
        retrieveArticles();

        const retrieveCategories = async () => {
            let data = await getCategories();
            setCategories(data);
        }

        retrieveCategories();

    }, [currentArticle, variantCats]);

    const DetailModal = ({object: {item}}) => (
        <Dialog open={openDetails} onClose={handleDetails} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Article details</DialogTitle>
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <Table className="align-items-center table-flush" responsive>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Article Name</td>
                                <td> {item.name}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Association</td>
                                <td> {item.association}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>Description</span></td>
                                <td>
                                    {item.description !== 'undefined' ? <div
                                        dangerouslySetInnerHTML={{
                                            __html: marked(hastagHighlight(item.description)),
                                        }}
                                    ></div> : ' '}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Price</td>
                                <td> {item.price}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Quantity</td>
                                <td> {item.quantity} </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Created at </span></td>
                                <td>  {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Updated at</span></td>
                                <td>  {moment(item.update_at).format("YYYY/MM/DD kk:mm:ss")}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <Row>
                                        <div className="col">
                                            <a href={CLIENT_URL + "product-detail-2?articleId=" + item.id} target="_blank">
                                                <Button
                                                    color="primary"
                                                >
                                                    Go to the article
                                                </Button></a>
                                        </div>
                                    </Row>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleDetails} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const VariantModal = ({object: item}) => (
        <Dialog open={openVariantModal} onClose={handleVariantModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Variant details</DialogTitle>
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <Table className="align-items-center table-flush" responsive>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Price</td>
                                <td> {item.price}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Quantity</td>
                                <td> {item.quantity} </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Created at </span></td>
                                <td>  {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <span>Updated at</span></td>
                                <td>  {moment(item.update_at).format("YYYY/MM/DD kk:mm:ss")}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        <h2>Feature Values</h2>
                        <br/>
                        <ul>
                            {item.feature_values?.map((elt) =>
                                <div key={elt.id}>
                                    <li> {elt.feature_name} : {elt.name}</li>
                                </div>)}
                        </ul>
                    </Row>
                </CardBody>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleVariantModal} color="danger">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    const DescriptionInfos = () => (
        <Dialog open={openDescriptions} onClose={handleDescription} aria-labelledby="form-dialog-title">
            <DialogContent>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <h2>Description formatter</h2>
                        <Table className="align-items-center table-flush" responsive>
                            <thead>
                            <th>Commands</th>
                            <th>Syntax</th>
                            <th>Description</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Bold</td>
                                <td>Sample content for <sup>**</sup> bold text <sup>**</sup></td>
                                <td>For bold, add <sup>**</sup> or __ to front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Italic</td>
                                <td>Sample content for <sup>*</sup> Italic text <sup>*</sup></td>
                                <td>For Italic, add <sup>*</sup> or _ to front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Bold and Italics</td>
                                <td><span>Sample content for <sup>***</sup> bold and Italic text <sup>***</sup> </span>
                                </td>
                                <td>For bold and Italics, add <sup>***</sup> to the front and back of the text.</td>
                            </tr>
                            <tr>
                                <td>Heading 1 <br/>
                                    Heading 6
                                </td>
                                <td>
                                    <span># Heading 1 content</span><br/>
                                    <span>###### Heading 6 content</span>
                                </td>
                                <td>For heading 1, add # to start of the line.<br/>
                                    For heading 6, add ###### to start of the line.
                                </td>
                            </tr>
                            <tr>
                                <td>Hastag</td>
                                <td>
                                    #Hastag
                                </td>
                                <td>#Hastag</td>

                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDescription} color="danger">
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    );

    return (
        <>
            <Dialog open={openDeleteModal} onClose={handleDeleteModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Delete Post</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this post ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={deletePost}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={6000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <ArticleHeader handleArticle={handleArticle} off={off}/>
            {/* Page content */}

            {openArticle ?
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Post new Article to sell</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="danger"
                                                onClick={handleArticle}
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Box sx={{width: '100%'}} style={{marginBottom: '15px'}}>
                                        <Stepper activeStep={activeStep}>
                                            {steps.map((label, index) => {
                                                const stepProps = {};
                                                const labelProps = {};
                                                if (isStepSkipped(index)) {
                                                    stepProps.completed = false;
                                                }
                                                return (
                                                    <Step key={label} {...stepProps}>
                                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Box>
                                    <React.Fragment>
                                        <Form onSubmit={handleSubmit(saveArticle)}>
                                            {activeStep === 0 &&
                                                <div>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col md="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-address"
                                                                    >
                                                                        Name <span style={{color: 'red'}}>*</span>
                                                                    </label>
                                                                    <Input
                                                                        name="name"
                                                                        className="form-control-alternative"
                                                                        id="input-address"
                                                                        placeholder="Name here ..."
                                                                        type="text"
                                                                        {...restName}
                                                                        innerRef={name}
                                                                        onChange={handleChangeInput}
                                                                    />
                                                                    {errors.name &&
                                                                        <FormHelperText style={{color: 'red'}}>At least
                                                                            5 characters are required
                                                                            !</FormHelperText>}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            {/*                                                             <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-first-name"
                                                                    >
                                                                        Quantity Present  <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-first-name"
                                                                        placeholder="10"
                                                                        type="number"
                                                                        {...restQuantity}
                                                                        innerRef={quantity}
                                                                    />
                                                                    {errors.quantity && <FormHelperText style={{ color: 'red' }}>Quantity must be positive !</FormHelperText>}
                                                                </FormGroup>
                                                            </Col> */}
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-last-name"
                                                                    >
                                                                        Price <span style={{color: 'red'}}>*</span>
                                                                    </label>
                                                                    <Input
                                                                        name="price"
                                                                        className="form-control-alternative"
                                                                        id="input-last-name"
                                                                        placeholder="20"
                                                                        type="number"
                                                                        {...restPrice}
                                                                        innerRef={price}
                                                                        onChange={handleChangeInput}
                                                                       // onKeyUp={handleChangeVariant}
                                                                        //onKeyPress={()=>console.log("on sort")}
                                                                    />
                                                                    {errors.price &&
                                                                        <FormHelperText style={{color: 'red'}}>Price
                                                                            must be positive !</FormHelperText>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-last-name"
                                                                    >
                                                                       Discount type
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        type="select"
                                                                        name="reduction_type"
                                                                        {...restReductionType}
                                                                        onChange={handleChangeInput}
                                                                        onClick={handleChangeVariant}
                                                                        value={currentArticle.reduction_type}
                                                                        innerRef={reduction_type}
                                                                        aria-invalid={errors.reductionType ? "true" : "false"}>
                                                                        <option value="">---Select a reduction type---</option>
                                                                        <option value="PERCENTAGE">PERCENTAGE</option>
                                                                        <option value="AMOUNT">AMOUNT</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-last-name"
                                                                    >
                                                                        Discount value
                                                                    </label>
                                                                    <Input
                                                                        name="reduction_price"
                                                                        className="form-control-alternative"
                                                                        id="input-last-name"
                                                                        placeholder="16"
                                                                        type="number"
                                                                        //disabled={reduction_type == null}
                                                                        {...restReductionPrice}
                                                                        innerRef={reduction_price}
                                                                        onChange={handleChangeInput}
                                                                       // onKeyUp={handleChangeVariant}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-last-name"
                                                                    >
                                                                        Tax(%)
                                                                    </label>
                                                                    <Input
                                                                        name="tax"
                                                                        className="form-control-alternative"
                                                                        id="input-last-name"
                                                                        placeholder="16"
                                                                        type="number"
                                                                        {...restTax}
                                                                        innerRef={tax}
                                                                        onChange={handleChangeInput}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="12">
                                                                <span>Price preview &nbsp;</span> :&nbsp;&nbsp;
                                                                {(currentArticle.price > 0  && currentArticle.reduction_price > 0)
                                                                    ?
                                                                    computePrice({
                                                                        price: currentArticle.price,
                                                                        reduction_type: currentVariant.reduction_type,
                                                                        reduction_price: currentArticle.reduction_price
                                                                    }) <= 0 ? <span
                                                                            style={{color: 'red'}}>{computePrice({
                                                                            price: currentArticle.price,
                                                                            reduction_type: currentVariant.reduction_type,
                                                                            reduction_price: currentArticle.reduction_price
                                                                        })}</span> :
                                                                        <span
                                                                            style={{color: 'green'}}>{computePrice({
                                                                            price: currentArticle.price,
                                                                            reduction_type: currentVariant.reduction_type,
                                                                            reduction_price: currentArticle.reduction_price
                                                                        })}</span>

                                                                    : <span>{currentArticle.price}</span>}
                                                            </Col>
                                                            <br/>
                                                            <br/>
                                                        </Row>
                                                    </div>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col md="12">
                                                                <FormGroup
                                                                    error={errors.association ? true : undefined}>
                                                                    <label
                                                                        className="form-control-label"
                                                                    >
                                                                        Association <span
                                                                        style={{color: 'red'}}>*</span>
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        type="select"
                                                                        {...restAssociation}
                                                                        innerRef={association}
                                                                        aria-invalid={errors.association ? "true" : "false"}>
                                                                        <option value="1">MEN</option>
                                                                        <option value="2">WOMEN</option>
                                                                        <option value="3">UNISEX</option>
                                                                    </Input>
                                                                    {errors.association &&
                                                                        <FormHelperText style={{color: 'red'}}>At least
                                                                            5 characters are
                                                                            required !</FormHelperText>}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="pl-lg-4 mb-3">
                                                        <Row>
                                                            <Col md="12">
                                                                <FormControl sx={{width: '100%'}}>
                                                                    <InputLabel id="demo-multiple-chip-label">Select
                                                                        Categories</InputLabel>
                                                                    <Select
                                                                        labelId="demo-multiple-chip-label"
                                                                        id="demo-multiple-chip"
                                                                        multiple
                                                                        value={selectedCategories}
                                                                        onChange={handleSelectCategory}
                                                                        input={<OutlinedInput id="select-multiple-chip"
                                                                                              label="Select Features"/>}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                flexWrap: 'wrap',
                                                                                gap: 0.5
                                                                            }}>
                                                                                {selected.map((value) => (
                                                                                    <Chip key={value}
                                                                                          label={(categories.find(obj => obj.id === value)).name}/>
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {categories.map((item) => (
                                                                            <MenuItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                                style={getStyles(item.name, selectedCategories, theme)}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col md="12">
                                                                <Tooltip title="Description Informations" placement="right">
                                                                    <i className="fa fa-info-circle fa-2x" aria-hidden="true" onClick={handleDescription}></i>
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Description <span style={{ color: 'red' }}>*</span></label>
                                                                    <textarea
                                                                        style={inputStyle}
                                                                        className="input"

                                                                        id="textarea"
                                                                        onChange={(e) => {
                                                                            updateMarkdown(e)
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <label>Preview </label>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: marked(hastagHighlight(markdown)),
                                                                        }}
                                                                    ></div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <Picker onEmojiClick={onEmojiClick} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <hr className="my-4"/>
                                                    <h6 className="heading-small text-muted mb-4">Add Images</h6>

                                                    <div>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            style={{display: 'none'}}
                                                            ref={inputRef}
                                                            onChange={handleUploadImage}/>
                                                    </div>

                                                    <div className="images">
                                                        {
                                                            images?.map((item) =>
                                                                <ImageComponent
                                                                    key={item.key}
                                                                    value={item.url}
                                                                    onRemoveElement={() => handleRemoveImage(item.key)}
                                                                />
                                                            )
                                                        }
                                                        <div className="pic" onClick={handleClickImageInput}>
                                                            ADD IMAGE
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {activeStep === 1 && <div>

                                                {featuresToLoad?.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <hr className="my-4"/>
                                                            <Row style={{marginTop: '15px'}}>
                                                                <Col lg="4">
                                                                    <h6 className="heading-small text-muted">{item.name}</h6>
                                                                </Col>
                                                                {/* <Col lg="4">
                                                                    <Input
                                                                        type="text"
                                                                        value={inputs[index]}
                                                                        aria-describedby="standard-weight-helper-text"
                                                                        inputProps={{ 'aria-label': 'weight', }}
                                                                        onChange={(e) =>
                                                                            setInputs((prev) => {
                                                                                return { ...prev, [index]: e.target.value };
                                                                            })
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Button onClick={() => { addFeaturesValue(index, item.id) }}>
                                                                        Add Value
                                                                    </Button>
                                                                </Col> */}
                                                            </Row>
                                                            <Row style={{marginTop: '15px'}}>
                                                                {item.feature_values?.map((elt, i) => {
                                                                    return (<Col lg="4" key={i}>
                                                                        <input
                                                                            type="radio"
                                                                            name={item.name}
                                                                            onClick={(e) => onFeatureChanged(e, item.id, elt.id, elt.value)}
                                                                        />
                                                                        &nbsp; &nbsp;<span>{elt.value}</span>
                                                                    </Col>)
                                                                })
                                                                }
                                                            </Row>
                                                        </div>)
                                                })}
                                                <hr className="my-4"/>
                                                <div className="pl-lg-4">
                                                    <Row style={{marginTop: '15px'}}>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                   Discount type
                                                                </label>
                                                                <Input
                                                                    value={variantReductionType}
                                                                    onChange={(e)=>setVariantReductionType(e.target.value)}
                                                                    className="form-control-alternative"
                                                                    type="select"

                                                                    aria-invalid={errors.reductionType ? "true" : "false"}>
                                                                    <option value="">---Select a reduction type---
                                                                    </option>
                                                                    <option value="PERCENTAGE">PERCENTAGE</option>
                                                                    <option value="AMOUNT">AMOUNT</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Discount value
                                                                </label>
                                                                <Input
                                                                    value={variantReductionPrice}
                                                                    className="form-control-alternative"
                                                                    id="input-last-name"
                                                                    placeholder="16"
                                                                    type="number"
                                                                    onChange={(e)=>setVariantReductionPrice(parseInt(e.target.value))}
                                                                   // onChange={(e)=>setVariantReductionPrice(e.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="pl-lg-4">
                                                    <Row style={{marginTop: '15px'}}>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    Quantity of Variant
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-first-name"
                                                                    placeholder="10"
                                                                    type="number"
                                                                    value={variantQuantity}
                                                                    onChange={(e) => handleChangeVariantQuantity(e)}
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Price of Variant
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-last-name"
                                                                    placeholder="20"
                                                                    type="number"
                                                                    value={variantPrice}
                                                                    onChange={(e) => handleChangeVariantPrice(e)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <hr className="my-4"/>
                                                <Row>
                                                    <Col md="12">
                                                        <span>Price preview &nbsp;</span> :&nbsp;&nbsp;
                                                        {(variantPrice > 0  && variantReductionPrice> 0)
                                                            ?
                                                            computePrice({
                                                                price: variantPrice,
                                                                reduction_type: variantReductionType,
                                                                reduction_price: variantReductionPrice
                                                            }) <= 0 ? <span
                                                                    style={{color: 'red'}}>{computePrice({
                                                                    price: variantPrice,
                                                                    reduction_type: variantReductionType,
                                                                    reduction_price: variantReductionPrice
                                                                })}</span> :
                                                                <span
                                                                    style={{color: 'green'}}>{computePrice({
                                                                    price: variantPrice,
                                                                    reduction_type: variantReductionType,
                                                                    reduction_price: variantReductionPrice
                                                                })}</span>

                                                            : <span>{variantPrice}</span>}
                                                    </Col>
                                                    <br/>
                                                    <br/>
                                                </Row>
                                                <hr className="my-4"/>
                                                <h6 className="heading-small text-muted mb-4">Add Variant Images</h6>
                                                <div>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{display: 'none'}}
                                                        ref={variantRef}
                                                        onChange={handleUploadVariant}/>
                                                </div>

                                                <div className="images">
                                                    {
                                                        variantImages?.map((item) =>
                                                            <ImageComponent
                                                                key={item.key}
                                                                value={item.url}
                                                                onRemoveElement={() => handleRemoveVariantImage(item.key)}
                                                            />
                                                        )
                                                    }
                                                    <div className="pic" onClick={handleClickVariantImageInput}>
                                                        ADD IMAGE
                                                    </div>
                                                </div>
                                                <div className="pl-lg-4">
                                                    <Row className="align-items-center">
                                                        <Col xs="8">
                                                            {/* <h3 className="mb-0"></h3> */}
                                                        </Col>
                                                        <Col className="text-right" xs="4">

                                                            <Button
                                                                color="primary"
                                                                type="button"
                                                                size="sm"
                                                                onClick={addVariant}
                                                            >
                                                                Add Variant
                                                            </Button>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                <hr className="my-4"/>
                                            </div>
                                            }


                                            {/*                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            type="text"
                                                                            value={item.value || ""}
                                                                            onChange={e => handleChangeFeature(index, e)}
                                                                        /> */}


                                            {activeStep === 2 &&
                                                <div>

                                                </div>
                                            }

                                            {progress > 0 && progress < 100 ?
                                                <div className="pl-lg-4">
                                                    <div className="text-center">{progress}%</div>
                                                    <Progress value={progress}/>
                                                </div> : ''
                                            }
                                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{mr: 1}}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{flex: '1 1 auto'}}/>
                                                {activeStep === steps.length - 1 ?
                                                    <div>
                                                        {submited ?
                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                size="sm"
                                                                disabled
                                                            >
                                                                Submit
                                                            </Button>
                                                            :
                                                            <Button
                                                                color="primary"
                                                                type="submit"
                                                                size="sm"
                                                            >
                                                                Submit
                                                            </Button>
                                                        }
                                                    </div> :

                                                    <Button onClick={handleNext}>
                                                        Next
                                                    </Button>
                                                }
                                            </Box>
                                        </Form>
                                    </React.Fragment>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="order-xl-1" xl="4">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Variant Products</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Discount value</th>
                                            <th scope="col">Discount type</th>
                                            <th scope="col">Features Values</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            variants?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        {item.variant_price}
                                                    </td>
                                                    <td>
                                                        {item.variant_quantity}
                                                    </td> <td>
                                                        {item.variant_reduction_price}
                                                    </td> <td>
                                                        {item.variant_reduction_type}
                                                    </td>
                                                    <td>
                                                        {item.variant_feature_values?.map((itm, i) => <div key={i}>
                                                            <span>{itm.value}</span><br/></div>)}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">

                                                            <Tooltip title="Remove" placement="top">
                                                                    <span
                                                                        className='material-icons'
                                                                        style={{cursor: 'pointer', color: 'firebrick'}}
                                                                        onClick={()=>{
                                                                            removeVariant(item.variant_key);
                                                                        }}
                                                                    >
                                                                        delete
                                                                    </span>
                                                            </Tooltip>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container> : ""}
            {openAddVariant ?
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Add Variant</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="danger"
                                                onClick={closeAddVariant}
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div>
                                            {featuresToLoad?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <hr className="my-4"/>
                                                        <Row style={{marginTop: '15px'}}>
                                                            <Col lg="4">
                                                                <h6 className="heading-small text-muted">{item.name}</h6>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{marginTop: '15px'}}>
                                                            {item.feature_values?.map((elt, i) => {
                                                                return (<Col lg="4" key={i}>
                                                                    <input
                                                                        type="radio"
                                                                        name={item.name}
                                                                        onClick={(e) => onFeatureChanged(e, item.id, elt.id, elt.value)}
                                                                    />
                                                                    &nbsp; &nbsp;<span>{elt.value}</span>
                                                                </Col>)
                                                            })
                                                            }
                                                        </Row>
                                                    </div>)
                                            })}
                                            <hr className="my-4"/>
                                            <div className="pl-lg-4">
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Discount type
                                                            </label>
                                                            <Input
                                                                value={variantReductionType}
                                                                onChange={(e)=>setVariantReductionType(e.target.value)}
                                                                className="form-control-alternative"
                                                                type="select"

                                                                aria-invalid={errors.reductionType ? "true" : "false"}>
                                                                <option value="">---Select a reduction type---
                                                                </option>
                                                                <option value="PERCENTAGE">PERCENTAGE</option>
                                                                <option value="AMOUNT">AMOUNT</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Reduction
                                                            </label>
                                                            <Input
                                                                value={variantReductionPrice}
                                                                className="form-control-alternative"
                                                                id="input-last-name"
                                                                placeholder="16"
                                                                type="number"
                                                                onChange={(e)=>setVariantReductionPrice(parseInt(e.target.value))}
                                                                // onChange={(e)=>setVariantReductionPrice(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="pl-lg-4">
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                Quantity of Variant
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-first-name"
                                                                placeholder="10"
                                                                type="number"
                                                                value={variantQuantity}
                                                                onChange={(e) => handleChangeVariantQuantity(e)}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Price of Variant
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-last-name"
                                                                placeholder="20"
                                                                type="number"
                                                                value={variantPrice}
                                                                onChange={(e) => handleChangeVariantPrice(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4"/>
                                            <h6 className="heading-small text-muted mb-4">Add Variant Images</h6>
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{display: 'none'}}
                                                    ref={variantRef}
                                                    onChange={handleUploadVariant}/>
                                            </div>

                                            <div className="images">
                                                {
                                                    variantImages?.map((item) =>
                                                        <ImageComponent
                                                            key={item.key}
                                                            value={item.url}
                                                            onRemoveElement={() => handleRemoveVariantImage(item.key)}
                                                        />
                                                    )
                                                }
                                                <div className="pic" onClick={handleClickVariantImageInput}>
                                                    ADD IMAGE
                                                </div>
                                            </div>
                                            <div className="pl-lg-4">
                                                <Row className="align-items-center">
                                                    <Col xs="8">
                                                        {/* <h3 className="mb-0"></h3> */}
                                                    </Col>
                                                    <Col className="text-right" xs="4">

                                                        <Button
                                                            color="primary"
                                                            type="button"
                                                            size="sm"
                                                            onClick={addVariant}
                                                        >
                                                            Add Variant
                                                        </Button>

                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4"/>

                                            <Button
                                                color="primary"
                                                type="button"
                                                size="sm"
                                                onClick={addVariants}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="order-xl-1" xl="4">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Variant Products</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Features Values</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            variants?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        {item.variant_price}
                                                    </td>
                                                    <td>
                                                        {item.variant_quantity}
                                                    </td>
                                                    <td>
                                                        {item.variant_feature_values?.map((itm, i) => <div key={i}>
                                                            <span>{itm.value}</span><br/></div>)}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">

                                                            <Tooltip title="Remove" placement="top">
                                                                    <span
                                                                        className='material-icons'
                                                                        style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                        delete
                                                                    </span>
                                                            </Tooltip>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container> : ""}
            {openEditArticle ?
                <Container className="mt--7 mb-3" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Edit Article</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="danger"
                                                onClick={closeEditArticle}
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(editArticle)}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Name <span style={{color: 'red'}}>*</span>
                                                        </label>
                                                        <Input
                                                            name="name"
                                                            value={currentArticle.name}
                                                            className="form-control-alternative"
                                                            id="input-address"
                                                            {...restName}
                                                            innerRef={name}
                                                            onChange={handleChangeInput}
                                                        />
                                                        {errors.name &&
                                                            <FormHelperText style={{color: 'red'}}>At least 5 characters
                                                                are required !</FormHelperText>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="pl-lg-4">
                                            <Row>
                                                {/*                                                 <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Quantity Present <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <Input
                                                            name="quanti"
                                                            value={currentArticle.quantity}
                                                            className="form-control-alternative"
                                                            id="input-first-name"
                                                            type="number"
                                                            {...restQuantity}
                                                            innerRef={quantity}
                                                        />
                                                        {errors.quantity && <FormHelperText style={{ color: 'red' }}>Quantity must be positive !</FormHelperText>}
                                                    </FormGroup>
                                                </Col> */}
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Price <span style={{color: 'red'}}>*</span>
                                                        </label>
                                                        <Input
                                                            name="price"
                                                            value={currentArticle.price}
                                                            className="form-control-alternative"
                                                            type="number"
                                                            {...restPrice}
                                                            innerRef={price}
                                                            onChange={handleChangeInput}
                                                        />
                                                        {errors.price &&
                                                            <FormHelperText style={{color: 'red'}}>Price must be
                                                                positive !</FormHelperText>}
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                           Discount type
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="select"
                                                            name="reduction_type"
                                                            onChange={handleChangeInput}
                                                            onClick={handleChangeVariant}
                                                            value={currentArticle.reduction_type}
                                                            innerRef={reduction_type}
                                                            aria-invalid={errors.reductionType ? "true" : "false"}>
                                                            <option value="">---Select a reduction type---</option>
                                                            <option value="PERCENTAGE">PERCENTAGE</option>
                                                            <option value="AMOUNT">AMOUNT</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Discount value
                                                        </label>
                                                        <Input
                                                            name="reduction_price"
                                                            value={currentArticle.reduction_price}
                                                            className="form-control-alternative"
                                                            type="number"
                                                            {...restReductionPrice}
                                                            innerRef={reduction_price}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Tax(%)
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="number"
                                                            name="tax"
                                                            {...restTax}
                                                            innerRef={tax}
                                                            value={currentArticle.tax}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <span>Price preview &nbsp;</span> :&nbsp;&nbsp;
                                                    {/*<span>{ computePrice(currentArticle)}</span>*/}
                                                    {(currentArticle.price > 0  && currentArticle.reduction_price > 0)
                                                        ?
                                                        computePrice(currentArticle) <= 0 ? <span
                                                                style={{color: 'red'}}>{computePrice(currentArticle)}</span> :
                                                            <span
                                                                style={{color: 'green'}}>{computePrice(currentArticle)}</span>
                                                        : <span>{currentArticle.price}</span>}
                                                </Col>
                                                <br/>
                                                <br/>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup error={errors.association ? true : undefined}>
                                                        <label
                                                            className="form-control-label"
                                                        >
                                                            Association<span style={{color: 'red'}}>*</span>
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="select"
                                                            name="association"
                                                            {...restAssociation}
                                                            innerRef={association}
                                                            aria-invalid={errors.association ? "true" : "false"}
                                                            onChange={handleChangeInput}>
                                                            <option value="1"
                                                                    selected={currentArticle.association == "MEN" || currentArticle.association == "1"}>MEN
                                                            </option>
                                                            <option value="2"
                                                                    selected={currentArticle.association == "WOMEN" || currentArticle.association == "2"}>WOMEN
                                                            </option>
                                                            <option value="3"
                                                                    selected={currentArticle.association == "UNISEX" || currentArticle.association == "3"}>UNISEX
                                                            </option>
                                                        </Input>
                                                        {errors.association &&
                                                            <FormHelperText style={{color: 'red'}}>At least 5 characters
                                                                are
                                                                required !</FormHelperText>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {currentArticle.categories.length > 0 &&
                                                <div>
                                                    <h6 className="heading-small text-muted"
                                                        style={{marginLeft: '15px'}}>Category of current article</h6>
                                                    <div className="pl-lg-4  mb-4">
                                                        <Row>
                                                            <Col md="12">
                                                                <Stack direction="row" spacing={1}>
                                                                    {currentArticle.categories?.map((item) => {
                                                                            return (
                                                                                <Chip key={item.id} label={item.name}/>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Stack>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            }
                                            <Row>
                                                <Col md="12 mb-4">
                                                    <FormControl sx={{width: '100%'}}>
                                                        <InputLabel id="demo-multiple-chip-label">Select
                                                            Categories</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={selectedCategories}
                                                            onChange={handleSelectCategory}
                                                            input={<OutlinedInput id="select-multiple-chip"
                                                                                  label="Select Features"/>}
                                                            renderValue={(selected) => (
                                                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value}
                                                                              label={(categories.find(obj => obj.id === value)).name}/>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {categories.map((item) => (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                    style={getStyles(item.name, selectedCategories, theme)}
                                                                >
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <Tooltip title="Description Informations" placement="right">
                                                        <i className="fa fa-info-circle fa-2x" aria-hidden="true" onClick={handleDescription}></i>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <label>Description <span style={{ color: 'red' }}>*</span></label>
                                                        <textarea
                                                            style={inputStyle}
                                                            id="textareaUpdate"
                                                            className="input"
                                                            value={markdownUpdated}
                                                            onChange={(e) => {
                                                                setMarkdownUpdated(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <label>Preview </label>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: marked(hastagHighlight(markdownUpdated)),
                                                            }}
                                                        ></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <Picker onEmojiClick={onUpdateEmojiClick} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4"/>
                                        <h6 className="heading-small text-muted mb-4">Add Images</h6>

                                        <div className="all-input-files">
                                            <input
                                                id="file0"
                                                type="file"
                                                accept="image/*"
                                                style={{display: 'none'}}
                                                ref={inputRef}
                                                onChange={handleUploadImage}/>
                                        </div>

                                        <div className="images">
                                            {
                                                savedImages?.map((item) =>
                                                    <ImageComponent
                                                        key={item.id}
                                                        value={MEDIA_URL + item.path}
                                                        onRemoveElement={() => removeToDeleteImage(item.id)}
                                                    />
                                                )
                                            }
                                            {
                                                images?.map((item) =>
                                                    <ImageComponent
                                                        key={item.key}
                                                        value={item.url}
                                                        onRemoveElement={() => handleRemoveImage(item.key)}
                                                    />
                                                )
                                            }
                                            <div className="pic" onClick={handleClickImageInput}>
                                                ADD IMAGE
                                            </div>
                                        </div>
                                        {progress > 0 && progress < 100 ?
                                            <div className="pl-lg-4">
                                                <div className="text-center">{progress}%</div>
                                                <Progress value={progress}/>
                                            </div> : ''
                                        }

                                        <div className="pl-lg-4">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    {/* <h3 className="mb-0"></h3> */}
                                                </Col>
                                                <Col className="text-right" xs="4">
                                                    {submited ?
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                            disabled
                                                        >
                                                            Submit
                                                        </Button>
                                                        :
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            size="sm"
                                                        >
                                                            Submit
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container> : ""}
            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Your Articles Here </h3>
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spin"} color="yellow" /> :
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Association</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                    {/*<th scope="col">Quantity</th>*/}
                                    <th scope="col">Date</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    articles?.map((item, index) =>
                                        <tr key={item.id}>
                                            <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                                    </span>
                                            </th>
                                            <th scope="row">
                                                <Media className="align-items-center">
                                                    <Media>
                                                            <span className="mb-0 text-sm">
                                                                {item.association}
                                                            </span>
                                                    </Media>
                                                </Media>
                                            </th>
                                            <td>{item.name}</td>
                                            <td>

                                                {item.price}
                                            </td>
                                            {/*<td>*/}
                                            {/*    {item.quantity}*/}

                                            {/*</td>*/}
                                            <td>
                                                {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                            </td>
                                            <td className="text-right">
                                                <div className="d-flex px-2 py-1">
                                                    <Tooltip title="Add Variant" placement="top">
                                                            <span
                                                                className="material-icons"
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    selectCategoryFeatures(item.categories);
                                                                    setVariantCats(item.categories);
                                                                    setVariantPrice(item.price);
                                                                    setVariantReductionType(item.reduction_type);
                                                                    setVariantReductionPrice(item.reduction_price);
                                                                    setCurrentArticle((prevState) => ({
                                                                        ...prevState,
                                                                        name: item.name,
                                                                        price: item.price,
                                                                        tax: item.tax,
                                                                        reduction_price: item.reduction_price,
                                                                        reduction_type: item.reduction_type,
                                                                        association: item.association,
                                                                        description: item.description,
                                                                        categories: item.categories,
                                                                        variants: item.variants,
                                                                    }));
                                                                    handleAddVariant();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'green'}}>add
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="Edit" placement="top">
                                                            <span
                                                                className="material-icons"
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    setMarkdownUpdated(item.description);
                                                                    setCurrentArticle((prevState) => ({
                                                                        ...prevState,
                                                                        name: item.name,
                                                                        price: item.price,
                                                                        tax: item.tax,
                                                                        reduction_price: item.reduction_price,
                                                                        reduction_type: item.reduction_type,
                                                                        association: item.association,
                                                                        description: item.description,
                                                                        categories: item.categories,
                                                                        variants: item.variants,
                                                                    }));
                                                                    setSavedImages(item.medias);
                                                                    handleEditArticle();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'orange'}}>edit
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                            <span
                                                                className='material-icons'
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    handleDeleteModal();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                delete
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="View Details" placement="top">
                                                            <span
                                                                className='material-icons danger'
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    handleDetails();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'dodgerblue'}}>
                                                                visibility
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="See Article Variants" placement="top">
                                                            <span
                                                                className='material-icons danger'
                                                                onClick={() => {
                                                                    setActiveObject({item});
                                                                    setCurrentArticle((prevState) => ({
                                                                        ...prevState,
                                                                        name: item.name,
                                                                        price: item.price,
                                                                        tax: item.tax,
                                                                        reduction_price: item.reduction_price,
                                                                        reduction_type: item.reduction_type,
                                                                        association: item.association,
                                                                        description: item.description,
                                                                        categories: item.categories,
                                                                        variants: item.variants,
                                                                    }));
                                                                    handleVariants();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'pink'}}>
                                                                list
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="Select for home page" placement="top">
                                                                <span
                                                                    className='material-icons danger'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        selectArticle(item.id);
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'blue' }}>
                                                                    check
                                                                </span>
                                                            </Tooltip>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>}
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
            {openVariantTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">All variants of {currentArticle.name} </h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="secondary"
                                            onClick={handleVariants}
                                            size="sm"
                                        >
                                            Back to Articles
                                        </Button>
                                    </Col>
                                </Row>
                                <h3 className="mb-0"></h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">Association</th>
                                    <th scope="col">Article Name</th>
                                    <th scope="col">Variant Price</th>
                                    <th scope="col">Variant Discount </th>
                                    <th scope="col">Variant Discount type</th>
                                    <th scope="col">Variant Quantity</th>
                                    <th scope="col">Feature Values</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    currentArticle.variants?.map((item) =>
                                        <tr key={item.id}>
                                            <th scope="row">
                                                <Media className="align-items-center">
                                                    <Media>
                                                            <span className="mb-0 text-sm">
                                                                {currentArticle.association}
                                                            </span>
                                                    </Media>
                                                </Media>
                                            </th>
                                            <td>{currentArticle.name}</td>
                                            <td>
                                                {item.price}
                                            </td><td>
                                                {item.reduction_price}
                                            </td><td>
                                                {item.reduction_type}
                                            </td>
                                            <td>
                                                {item.quantity}

                                            </td>
                                            <td>
                                            {item.feature_values?.map((itm, i) => <>
                                                            {itm.name} {i + 1 !== item.feature_values.length ? "|" : ""} </>)}
                                            </td>
                                            <td className="text-right">
                                                <div className="d-flex px-2 py-1">
                                                    <Tooltip title="Edit" placement="top">
                                                            <span
                                                                className="material-icons"
                                                                onClick={() => {
                                                                    setCurrentVariant((prevState) => ({
                                                                        ...prevState,
                                                                        id: item.id,
                                                                        price: item.price,
                                                                        quantity: item.quantity,
                                                                        feature_values: item.feature_values,
                                                                        reduction_type: item.reduction_type,
                                                                        reduction_price: item.reduction_price
                                                                    }));
                                                                    setVariantReductionPrice(item.reduction_price);
                                                                    setVariantReductionType(item.reduction_type);
                                                                    setSavedVariantImages(item.medias);
                                                                    handleEditVariant();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'orange'}}>edit
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                            <span
                                                                className='material-icons'
                                                                onClick={() => {
                                                                    //setActiveObject({ item });
                                                                    //handleDeleteModal();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                delete
                                                            </span>
                                                    </Tooltip>
                                                    <Tooltip title="View features values" placement="top">
                                                            <span
                                                                className='material-icons'
                                                                onClick={() => {
                                                                    setCurrentVariant((prevState) => ({
                                                                        ...prevState,
                                                                        id: item.id,
                                                                        price: item.price,
                                                                        quantity: item.quantity,
                                                                        reduction_price: item.reduction_price,
                                                                        reduction_type: item.reduction_type,
                                                                        feature_values: item.feature_values,
                                                                    }));
                                                                    setVariantReductionPrice(parseInt(item.reduction_price));
                                                                    setVariantReductionType(item.reduction_type);
                                                                    handleVariantModal();
                                                                }}
                                                                style={{cursor: 'pointer', color: 'blue'}}>
                                                                visibility
                                                            </span>
                                                    </Tooltip>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
            {openEditVariant ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Update variant</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={closeEditVariant}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <div className="pl-lg-4">
                                        <Row style={{marginTop: '15px'}}>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                       Discount type
                                                    </label>
                                                    <Input
                                                        value={variantReductionType}
                                                        onChange={(e)=>setVariantReductionType(e.target.value)}
                                                        className="form-control-alternative"
                                                        type="select"

                                                        aria-invalid={errors.reductionType ? "true" : "false"}>
                                                        <option value="">---Select a reduction type---
                                                        </option>
                                                        <option value="PERCENTAGE">PERCENTAGE</option>
                                                        <option value="AMOUNT">AMOUNT</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Reduction
                                                    </label>
                                                    <Input
                                                        value={variantReductionPrice}
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        placeholder="16"
                                                        type="number"
                                                        onChange={(e)=>setVariantReductionPrice(parseInt(e.target.value))}
                                                        // onChange={(e)=>setVariantReductionPrice(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="pl-lg-4">
                                        <Row style={{marginTop: '15px'}}>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Quantity of Variant
                                                    </label>
                                                    <Input
                                                        name="quantity"
                                                        min={1}
                                                        value={currentVariant.quantity}
                                                        className="form-control-alternative"
                                                        type="number"
                                                        onChange={handleChangeVariant}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Price of Variant
                                                    </label>
                                                    <Input
                                                        name="price"
                                                        min={0}
                                                        value={currentVariant.price}
                                                        className="form-control-alternative"
                                                        type="number"
                                                        onChange={handleChangeVariant}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4"/>
                                    <h6 className="heading-small text-muted mb-4">Add Variant Images</h6>
                                    <div>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{display: 'none'}}
                                            ref={variantRef}
                                            onChange={handleUploadVariant}/>
                                    </div>

                                    <div className="images">
                                        {
                                            savedVariantImages?.map((item) =>
                                                <ImageComponent
                                                    key={item.id}
                                                    value={MEDIA_URL + item.path}
                                                    onRemoveElement={() => removeToDeleteVariantImage(item.id)}
                                                />
                                            )
                                        }
                                        {
                                            variantImages?.map((item) =>
                                                <ImageComponent
                                                    key={item.key}
                                                    value={item.url}
                                                    onRemoveElement={() => handleRemoveVariantImage(item.key)}
                                                />
                                            )
                                        }
                                        <div className="pic" onClick={handleClickVariantImageInput}>
                                            ADD IMAGE
                                        </div>
                                    </div>

                                    <div>

                                        <Button
                                            color="primary"
                                            type="button"
                                            size="sm"
                                            onClick={editVariant}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}

            {openDetails ? <DetailModal object={activeObject}/> : null}
            {openVariantModal ? <VariantModal object={currentVariant}/> : null}
            {openDescriptions ? <DescriptionInfos/> : null}
        </>
    );
};

export default Articles;
