/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Button,
  Container,
  Media,
} from "reactstrap";

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthContext";
import { useTranslation } from "react-i18next";
//import { MEDIA_URL } from "../../constants";
import { useLocation } from "react-router-dom";
import { getNotifications } from "services/UserServices";
import MuiAlert from '@mui/material/Alert';
import { Badge, Box, Snackbar } from "@mui/material";
import { clearNotif } from "services/UserServices";
import moment from "moment";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import { readNotif } from "services/UserServices";
import { useHistory } from "react-router-dom";
import { socket } from "index";
import { deleteNotif } from "services/UserServices";
import { countNotif } from "services/UserServices";
import { readNotifByUserAndChat } from "services/UserServices";

import env from "ts-react-dotenv";

const MEDIA_URL = env.MEDIA_URL;
//const MEDIA_URL = "http://127.0.0.1:8000/media/";

const shapeStyles = { bgcolor: 'primary.main', width: 40, height: 40 };
const shapeCircleStyles = { borderRadius: '50%' };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminNavbar = (props) => {

  const { user, logOut } = useContext(AuthContext);
  const [lang, setLang] = useState("en");
  const { t, i18n } = useTranslation();
  const [inUrl, setInUrl] = useState("e");
  const [notifications, setNotifications] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [nbUnread, setNbUnread] = useState(0);
  const history = useHistory();

  const urlLink = window.location.href;

  let location = useLocation();

  useEffect(() => {
    let srcLink = window.location.href;
    setInUrl(srcLink);

    const retrieveNotifications = async () => {
      let data = await getNotifications(user?.id);
      setNotifications(data);
    }

    const getCountNotifications = async () => {
      let nb = await countNotif(user?.id);
      setNbUnread(parseInt(nb));
    }

    if (user) {
      // retrieveNotifications();
      // getCountNotifications();
    }
  }, [location, user])

  const retrieveNotifications = async (user_id) => {
    let data = await getNotifications(user_id);
    setNotifications(data);
  }

  const getCountNotifications = async (user_id) => {
    let nb = await countNotif(user_id);
    setNbUnread(parseInt(nb));
  }

  useEffect(() => {

    // const readCurrentNotifications = async (user_id, chat_id) => {
    //   await readNotifByUserAndChat(user_id, chat_id);
    // }
    //
    // const getCountNotifications = async (user_id) => {
    //   let nb = await countNotif(user_id);
    //   setNbUnread(parseInt(nb));
    // }

    /*socket.on("receive_notification", (data) => {
      console.log('we got the notif');
      if (urlLink.includes("single-chat")) {
        console.log('nous somme dans la page de chat....');
        readCurrentNotifications(data.user_id, data.chat_id);
      }
      getCountNotifications(data.user_id);

    });*/
  }, [nbUnread, urlLink]);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const changeLanguge = (lang) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  const clearNotifications = async () => {

    try {
      let data = await clearNotif(user?.id);
      setNotifications(data);
      getCountNotifications(user?.id);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  }

  const delNotifications = async () => {

    try {
      let data = await deleteNotif(user?.id);
      setNotifications(data);
      setAlertMessage("Notifications sucessfully cleared !");
      setAlertType("success");
      handleOpenAlert();

    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  }

  const goToAllChat = async (id) => {
    console.log(id);
    try {
      await readNotif(id);
      retrieveNotifications();
      history.push("/admin/manage-all-chats");

    } catch (error) {

    }
  }

  const goToChat = async (notif_id, chat_id) => {
    try {
      await readNotif(notif_id);
      retrieveNotifications();
      props.history.push({
        pathname: '/admin/single-chat',
        search: '?chatId=' + chat_id
    });

    } catch (error) {

    }
  }


  const { path } = useLocation();
  // ...
  if (path === "/admin/invoice" ) {
    return <></>;
  }


  return (
    <>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h4 className="text-white text-uppercase">
            {inUrl.includes('team') ? "TEAM" : ""} {inUrl.includes('about') ? "ABOUT US" : ""} {inUrl.includes('f-a-q') ? "FAQ" : ""} {inUrl.includes('single-chat') ? "CHAT PAGE" : ""} {props.brandText} (ADMIN connected)
          </h4>
          {/*           <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder={t("search")} type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
{/*
          <Nav className="align-items-center d-none mr-3 d-md-flex ml-lg-auto" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require(`../../assets/img/theme/${lang}.png`)
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                       {lang}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Language</h6>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguge("en")}>
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/en.png")
                          .default
                      }
                    />
                  </span>
                   <span>en</span>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguge("fr")}>
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/fr.png")
                          .default
                      }
                    />
                  </span>
                   <span>fr</span>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguge("de")}>
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/de.png")
                          .default
                      }
                    />
                  </span>
                   <span>de</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
*/}
{/*
          <Nav className="align-items-center d-none d-md-flex" navbar style={{ zIndex: 9 }}>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>

                <Badge onClick={clearNotifications} color="error" overlap="circular" badgeContent={nbUnread}>
                  <NotificationsIcon fontSize="large" />
                </Badge>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right >
                {
                  notifications?.map(item => {
                    return (
                      <div>
                        {["ALL_CHAT", 1].includes(item.type) ? <DropdownItem onClick={() => goToAllChat(item.id)} >
                          {item.title} {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}

                          <p>{item.content}</p>

                        </DropdownItem> :
                          <DropdownItem onClick={() => goToChat(item.id, item.chat_id)} >
                            {item.title} {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}

                            <p>{item.content}</p>

                          </DropdownItem>}
                      </div>

                    )
                  })
                }

                <DropdownItem divider />
                <DropdownItem>
                  <div className="text-center">
                    <Button onClick={delNotifications} className="" color="danger" type="button">
                      Clear all Notifications
                    </Button>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
*/}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                  {MEDIA_URL &&
                    <img
                      alt="..."
                      src={MEDIA_URL + user?.avatar}
                    />}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user ? user.username : ""}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                {/*<DropdownItem to="/admin/user-profile" tag={Link}>*/}
                {/*  <i className="ni ni-settings-gear-65" />*/}
                {/*  <span>Settings</span>*/}
                {/*</DropdownItem>*/}
                <DropdownItem to="/admin/index" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                {/*                 <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem>
                  <div className="text-center">
                    <Button onClick={logOut} className="" color="danger" type="button">
                      Logout
                    </Button>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
