/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Progress,
} from "reactstrap";
// core components
import AboutHeader from "components/Headers/AboutHeader.js";
import React, { useContext, useEffect } from "react";

import { AuthContext } from "context/AuthContext";

const About = () => {

    const { user } = useContext(AuthContext);

    useEffect(() => {

    }, []);

    return (
        <>
            <AboutHeader />
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Learn About Dodo-shop Here ! </h3>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                </Row>
                            </CardBody>
                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> 
        </>
    );
};

export default About;
