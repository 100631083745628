/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Row,
    Table,
} from "reactstrap";
// core components
import FaqHeader from "components/Headers/FaqHeader.js";
import React, { useContext, useEffect, useState } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { AuthContext } from "context/AuthContext";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import {
    createFaq,
    deleteFaq,
    getFaqs,
    updateFaq,
} from "services/AdminServices";
import { hastagHighlight, inputStyleFaq } from "../../../constants";
import { marked } from "marked";
import Picker from "emoji-picker-react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { deleteCategeory } from "../../../services/CategoryServices";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Faq = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const { ref: question, ...restQuestion } = register("question", { required: true, minLength: 5 });
    const { ref: answer, ...restAnswer } = register("answer", { required: true, minLength: 5 });


    const { user } = useContext(AuthContext);
    const [openFaq, setOpenFaq] = useState(false);
    const [openEditFaq, setOpenEditFaq] = useState(false);
    const [openTable, setOpenTable] = useState(true);
    const [off, setOff] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [lastPage, setLastPage] = useState(1);
    const [activeObject, setActiveObject] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [faq_id, setfaq_id] = useState("");
    const [currentFaq, setCurrentFaq] = useState({
        answer: "",
        question: "",
    });
    const [isLoading, setLoading] = useState(true);
    const [faqValue, setFaqValue] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    //english
    const [markdownUpdatedEnQuestion, setMarkdownUpdatedEnQuestion] = useState('');
    const [markdownUpdatedEnAnswer, setMarkdownUpdatedEnAnswer] = useState('');
    //french
    const [markdownUpdatedFrQuestion, setMarkdownUpdatedFrQuestion] = useState('');
    const [markdownUpdatedFrAnswer, setMarkdownUpdatedFrAnswer] = useState('');
    //German
    const [markdownUpdatedDeQuestion, setMarkdownUpdatedDeQuestion] = useState('');
    const [markdownUpdatedDeAnswer, setMarkdownUpdatedDeAnswer] = useState('');
    //english
    const [markdownUpdatedEnQuestionEdit, setMarkdownUpdatedEnQuestionEdit] = useState('');
    const [markdownUpdatedEnAnswerEdit, setMarkdownUpdatedEnAnswerEdit] = useState('');
    //french
    const [markdownUpdatedFrQuestionEdit, setMarkdownUpdatedFrQuestionEdit] = useState('');
    const [markdownUpdatedFrAnswerEdit, setMarkdownUpdatedFrAnswerEdit] = useState('');
    //German
    const [markdownUpdatedDeQuestionEdit, setMarkdownUpdatedDeQuestionEdit] = useState('');
    const [markdownUpdatedDeAnswerEdit, setMarkdownUpdatedDeAnswerEdit] = useState('');

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEnglish, setOpenEnglish] = useState(true);
    const [openFrench, setOpenFrench] = useState(true);
    const [openGerman, setOpenGerman] = useState(true);

    const onUpdateEmojiClickEnQuestion = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateEnQuestion");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedEnQuestion(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickEnAnswer = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateEnAnswer");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedEnAnswer(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickFrQuestion = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateFrQuestion");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedFrQuestion(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickFrAnswer = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateFrAnswer");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedFrAnswer(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickDeQuestion = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateDeQuestion");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedDeQuestion(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickDeAnswer = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateDeAnswer");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedDeAnswer(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickEnQuestionEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateEnQuestionEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedEnQuestionEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickEnAnswerEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateEnAnswerEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedEnAnswerEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickFrQuestionEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateFrQuestionEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedFrQuestionEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickFrAnswerEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateFrAnswerEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedFrAnswerEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickDeQuestionEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateDeQuestionEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedDeQuestionEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const onUpdateEmojiClickDeAnswerEdit = (event, emojiObject) => {
        let input = document.getElementById("textareaUpdateDeAnswerEdit");
        if (input.selectionStart || input.selectionStart === '0') {
            let startpos = input.selectionStart;
            let endpos = input.selectionEnd;
            let restore = input.scrollTop;
            input.value = input.value.substring(0, startpos) + emojiObject.emoji + input.value.substring(endpos, input.value.length);
            setMarkdownUpdatedDeAnswerEdit(input.value);
            if (restore > 0) {
                input.scrollTop = restore;
            }
            input.focus();
            input.selectionStart = startpos + emojiObject.emoji.length;
            input.selectionEnd = startpos + emojiObject.emoji.length;
        } else {
            input.value += emojiObject.emoji;
            input.focus();
        }
    }

    const handleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const retrieveFaqs = async () => {
        let data = await getFaqs();
        let arr = Object.values(data);
        setFaqs(arr);
        setLoading(false);
    };

    const handleFaq = () => {
        setOpenFaq(!openFaq);
        setOpenTable(!openTable);
        setOff(!off);
        reset();
        setCurrentFaq((prevState) => ({
            ...prevState,
            question: "",
            answer: "",
        }));
    };

    useEffect(() => {
        const retrieveFaqs = async () => {
            let data = await getFaqs();
            let arr = Object.values(data);
            setFaqs(arr);
            setLoading(false);
        }
        retrieveFaqs();
    }, []);

    const saveFaq = async () => {

        setSubmited(true);

        let data = {
            type: "FAQ",
            question_en: markdownUpdatedEnQuestion,
            answer_en: markdownUpdatedEnAnswer,
            question_fr: markdownUpdatedFrQuestion,
            answer_fr: markdownUpdatedFrAnswer,
            question_de: markdownUpdatedDeQuestion,
            answer_de: markdownUpdatedDeAnswer
        }
        try {

            await createFaq(data);

            setMarkdownUpdatedEnQuestion("");
            setMarkdownUpdatedEnAnswer("");
            setMarkdownUpdatedFrQuestion("");
            setMarkdownUpdatedFrAnswer("");
            setMarkdownUpdatedDeQuestion("");
            setMarkdownUpdatedDeAnswer("");
            setOpenFaq(!openFaq);
            setOpenTable(!openTable);
            setOff(!off);

            retrieveFaqs();

            setAlertMessage("Faq created sucessfully");
            setAlertType("success");
            handleOpenAlert();
            reset();
            setCurrentFaq((prevState) => ({
                ...prevState,
                question: "",
                answer: "",
            }));
            setSubmited(false);
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            reset();
            setCurrentFaq((prevState) => ({
                ...prevState,
                question: "",
                answer: "",
            }));
            setSubmited(false);
        }
    }

    const handleFaqEdit = () => {
        setOpenEditFaq(!openEditFaq);
        setOpenTable(!openTable);
        setOff(!off);

    };

    const updateCurrentFaq = async () => {

        setSubmited(true);

        let data = {
            question_en: markdownUpdatedEnQuestionEdit,
            answer_en: markdownUpdatedEnAnswerEdit,
            question_fr: markdownUpdatedFrQuestionEdit,
            answer_fr: markdownUpdatedFrAnswerEdit,
            question_de: markdownUpdatedDeQuestionEdit,
            answer_de: markdownUpdatedDeAnswerEdit
        }
        try {

            await updateFaq(faq_id, data);

            setfaq_id("");
            setMarkdownUpdatedEnQuestionEdit("");
            setMarkdownUpdatedEnAnswerEdit("");
            setMarkdownUpdatedFrQuestionEdit("");
            setMarkdownUpdatedFrAnswerEdit("");
            setMarkdownUpdatedDeQuestionEdit("");
            setMarkdownUpdatedDeAnswerEdit("");

            setOpenEditFaq(false);
            setOpenTable(true);
            setAlertMessage("Faq updated successfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveFaqs();
            setOff(!off);
            setSubmited(false);
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
            setOff(!off);
            setSubmited(false);
        }
    }

    const delFaq = async () => {
        setOpenDeleteModal(false);

        let faq_id = activeObject.item?.id;

        try {
            await deleteFaq(faq_id);

            setAlertMessage("Faq deleted successfully");
            setAlertType("success");
            handleOpenAlert();

            retrieveFaqs();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    return (
        <>
            <Dialog
                open={openDeleteModal}
                onClose={handleDeleteModal}
                aria-labelledby="form-dialog-title"
            >
                <form>
                    <DialogTitle id="form-dialog-title">Delete faq</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to delete this faq ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteModal} color="secondary">
                            Cancel
                        </Button>
                        <Button type="button" onClick={delFaq} color="danger">
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <FaqHeader handleFaq={handleFaq} off={off} />
            {/* Page content */}

            {openFaq ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Create a new FAQ</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleFaq}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="heading-small text-muted mb-4">English</h6>
                                {openEnglish ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenEnglish(!openEnglish)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenEnglish(!openEnglish)}/>
                                        }
                                <hr className="my-4" />
                                {openEnglish && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                               {/* <textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateEnQuestion"
                                                    className="input"
                                                    value={markdownUpdatedEnQuestion}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnQuestion(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnQuestion(e.target.value)
                                                    }}
                                                    id="textareaUpdateEnQuestion"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."
                                                    value={markdownUpdatedEnQuestion}
                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedEnQuestion)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickEnQuestion} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateEnAnswer"
                                                    className="input"
                                                    value={markdownUpdatedEnAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnAnswer(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedEnAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnAnswer(e.target.value)
                                                    }}
                                                    id="textareaUpdateEnAnswer"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedEnAnswer)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickEnAnswer} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}
                                <h6 className="heading-small text-muted mb-4">French</h6>
                                {openFrench ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenFrench(!openFrench)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenFrench(!openFrench)}/>
                                        }
                                <hr className="my-4" />
                                {openFrench && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateFrQuestion"
                                                    className="input"
                                                    value={markdownUpdatedFrQuestion}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrQuestion(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrQuestion(e.target.value)
                                                    }}
                                                    id="textareaUpdateFrQuestion"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."
                                                    value={markdownUpdatedFrQuestion}
                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedFrQuestion)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickFrQuestion} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                               {/* <textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateFrAnswer"
                                                    className="input"
                                                    value={markdownUpdatedFrAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrAnswer(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedFrAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrAnswer(e.target.value)
                                                    }}
                                                    id="textareaUpdateFrAnswer"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedFrAnswer)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickFrAnswer} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}
                                <h6 className="heading-small text-muted mb-4">German</h6>
                                {openGerman ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenGerman(!openGerman)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenGerman(!openGerman)}/>
                                        }
                                <hr className="my-4" />
                                {openGerman && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                               {/* <textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateDeQuestion"
                                                    className="input"
                                                    value={markdownUpdatedDeQuestion}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeQuestion(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeQuestion(e.target.value)
                                                    }}
                                                    id="textareaUpdateDeQuestion"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."
                                                    value={markdownUpdatedDeQuestion}
                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedDeQuestion)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickDeQuestion} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateDeAnswer"
                                                    className="input"
                                                    value={markdownUpdatedDeAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeAnswer(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedDeAnswer}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeAnswer(e.target.value)
                                                    }}
                                                    id="textareaUpdateDeAnswer"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedDeAnswer)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickDeAnswer} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}
                                <div className="pl-lg-4">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            {/* <h3 className="mb-0"></h3> */}
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                type="button"
                                                size="sm"
                                                onClick={saveFaq}
                                                disabled={submited}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}

            {openEditFaq ? <Container className="mt--7 mb-3" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Update FAQ</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="danger"
                                            onClick={handleFaqEdit}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            <h6 className="heading-small text-muted mb-4">English</h6>
                                {openEnglish ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenEnglish(!openEnglish)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenEnglish(!openEnglish)}/>
                                        }
                                <hr className="my-4" />
                                {openEnglish && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateEnQuestionEdit"
                                                    className="input"
                                                    value={markdownUpdatedEnQuestionEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnQuestionEdit(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnQuestionEdit(e.target.value)
                                                    }}
                                                    id="textareaUpdateEnQuestionEdit"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."
                                                    value={markdownUpdatedEnQuestionEdit}
                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedEnQuestionEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickEnQuestionEdit} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateEnAnswerEdit"
                                                    className="input"
                                                    value={markdownUpdatedEnAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnAnswerEdit(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedEnAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedEnAnswerEdit(e.target.value)
                                                    }}
                                                    id="textareaUpdateEnAnswerEdit"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedEnAnswerEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickEnAnswerEdit} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}
                                <h6 className="heading-small text-muted mb-4">French</h6>
                                {openFrench ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenFrench(!openFrench)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenFrench(!openFrench)}/>
                                        }
                                <hr className="my-4" />
                                {openFrench && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                              {/*  <textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateFrQuestionEdit"
                                                    className="input"
                                                    value={markdownUpdatedFrQuestionEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrQuestionEdit(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedFrQuestionEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrQuestionEdit(e.target.value)
                                                    }}
                                                    id="textareaUpdateFrQuestionEdit"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedFrQuestionEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickFrQuestionEdit} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateFrAnswerEdit"
                                                    className="input"
                                                    value={markdownUpdatedFrAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrAnswerEdit(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedFrAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedFrAnswerEdit(e.target.value)
                                                    }}
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."
                                                    id="textareaUpdateFrAnswerEdit"
                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedFrAnswerEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickFrAnswerEdit} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}
                                <h6 className="heading-small text-muted mb-4">German</h6>
                                {openGerman ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenGerman(!openGerman)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenGerman(!openGerman)}/>
                                        }
                                <hr className="my-4" />
                                {openGerman && <div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Question <span style={{ color: 'red' }}>*</span></label>
                                                <br />
                                               {/* <textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateDeQuestionEdit"
                                                    className="input"
                                                    value={markdownUpdatedDeQuestionEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeQuestionEdit(e.target.value)
                                                    }}
                                                />*/}

                                                <Input
                                                    value={markdownUpdatedDeQuestionEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeQuestionEdit(e.target.value)
                                                    }}
                                                    id="textareaUpdateDeQuestionEdit"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                       {/* <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedDeQuestionEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickDeQuestionEdit} />
                                        </Col>*/}
                                    </Row>
                                </div>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Answer <span style={{ color: 'red' }}>*</span></label> <br />
                                                {/*<textarea
                                                    style={inputStyleFaq}
                                                    id="textareaUpdateDeAnswerEdit"
                                                    className="input"
                                                    value={markdownUpdatedDeAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeAnswerEdit(e.target.value)
                                                    }}
                                                />*/}
                                                <Input
                                                    value={markdownUpdatedDeAnswerEdit}
                                                    onChange={(e) => {
                                                        setMarkdownUpdatedDeAnswerEdit(e.target.value)
                                                    }}
                                                    id="textareaUpdateDeAnswerEdit"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    placeholder="Content here ..."

                                                    rows={3}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup>
                                                <label>Preview </label>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: marked(hastagHighlight(markdownUpdatedDeAnswerEdit)),
                                                    }}
                                                ></div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Picker onEmojiClick={onUpdateEmojiClickDeAnswerEdit} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>}

                                <div className="pl-lg-4">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            {/* <h3 className="mb-0"></h3> */}
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                type="button"
                                                size="sm"
                                                onClick={updateCurrentFaq}
                                                disabled={submited}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> : ""}

            {openTable ? <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Your Faqs Here </h3>
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spin"} color="yellow" /> :
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Question</th>
                                            <th scope="col">Answer</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            faqs?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {item?.question_en !== 'undefined' ? <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: marked(hastagHighlight((item?.question_en).substring(1, 50))),
                                                            }}
                                                        ></div> : ' '}
                                                    </td>
                                                    <td>
                                                        {item?.answer_en !== 'undefined' ? <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: marked(hastagHighlight((item?.answer_en).substring(1, 50))),
                                                            }}
                                                        ></div> : ' '}
                                                    </td>
                                                    {/*<td>

                                                        {item?.answer_en}
                                                    </td>*/}
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">
                                                            <Tooltip title="Edit" placement="top">
                                                                <span
                                                                    className="material-icons"
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        setMarkdownUpdatedEnQuestionEdit(item?.question_en ? item?.question_en : "");
                                                                        setMarkdownUpdatedEnAnswerEdit(item?.answer_en ? item?.answer_en : "");
                                                                        setMarkdownUpdatedFrQuestionEdit(item?.question_fr ? item?.question_fr : "");
                                                                        setMarkdownUpdatedFrAnswerEdit(item?.answer_fr ? item?.answer_fr : "");
                                                                        setMarkdownUpdatedDeQuestionEdit(item?.question_de ? item?.question_de : "");
                                                                        setMarkdownUpdatedDeAnswerEdit(item?.answer_de ? item?.answer_de : "");
                                                                        setOpenTable(false);
                                                                        setOpenEditFaq(true);
                                                                        setOff(!off);
                                                                        setfaq_id(item?.id);
                                                                        //handleEditFaq();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'orange' }}>edit
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" placement="top">
                                                                <span
                                                                    className='material-icons'
                                                                    onClick={() => {
                                                                        setActiveObject({ item });
                                                                        handleDeleteModal();
                                                                    }}
                                                                    style={{ cursor: 'pointer', color: 'firebrick' }}>
                                                                    delete
                                                                </span>
                                                            </Tooltip>
                                                            {/*<Tooltip title="View Details" placement="top">*/}
                                                            {/*        <span*/}
                                                            {/*            className='material-icons danger'*/}
                                                            {/*            onClick={() => {*/}
                                                            {/*                setActiveObject({item});*/}
                                                            {/*                //handleDetails();*/}
                                                            {/*            }}*/}
                                                            {/*            style={{cursor: 'pointer', color: 'dodgerblue'}}>*/}
                                                            {/*            visibility*/}
                                                            {/*        </span>*/}
                                                            {/*</Tooltip>*/}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>}
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                <select className="form-control" onChange={(e) => {
                                                    //handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={""}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container> : ""}
        </>
    );
};

export default Faq;
