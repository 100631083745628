import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col, Button } from "reactstrap";

const AgbHeader = ({ handleAgb, off }) => {
    return (
        <div
            className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
            style={{
                minHeight: "200px",
                backgroundImage:
                    "url(" +
                    require("../../assets/img/theme/profile-cover.jpg").default +
                    ")",
                backgroundSize: "cover",
                backgroundPosition: "center top",
            }}
        >
            {/* Mask */}
            <span className="mask bg-gradient-default opacity-8" />
            {/* Header container */}
            <Container className="d-flex align-items-center" fluid>
                <Row>
                    <Col lg="12" md="10">
                    {off ?
                <Button
                  color="warning"
                  disabled
                  onClick={handleAgb}
                >
                  Add AGB
                </Button>
                :
                <Button
                  color="warning"
                  onClick={handleAgb}
                >
                  Add AGB
                </Button>
              }
                    </Col>
                </Row>
            </Container>
        </div>

    );
};

export default AgbHeader;
