import axios from "axios";

export function getPurchasesPaginated(id, nb_of_element) {
    let formData = new FormData();
    formData.append("numberElement", nb_of_element);
    if (id !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                .get('/paginate/purchases?page='+`${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            axios
                .get('/paginate/purchases')
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function changePurchaseStatus(id, status) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/change/status/${status}/purchase/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getAllPaginatedPurchases(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/purchases/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchases/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function allPaginatedPurchases(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/all/purchases/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/all/purchases/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}

export function download(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/invoice/purchase/${id}`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
}