import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Nav
} from "reactstrap";

// core components
import NewsHeader from "components/Headers/NewsHeader.js";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import './filestyle.css';
import './videostyle.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {AuthContext} from "context/AuthContext";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from "@material-ui/core/Tooltip";
import 'video-react/dist/video-react.css';
import moment from "moment";
import FormHelperText from '@material-ui/core/FormHelperText';
import {getFeatures} from "services/FeatureServices";
import FeatureHeader from "components/Headers/FeatureHeader";
import {updateFeature} from "services/FeatureServices";
import {createFeature} from "services/FeatureServices";
import {deleteFeature} from "services/FeatureServices";
import ReactLoading from "react-loading";
import {addValue} from "services/FeatureServices";
import {getSelectedCategories} from "services/CategoryServices";
import {getSelectedArticles} from "services/ArticleServices";
import {deleteSelectedCategeory} from "services/CategoryServices";
import {deleteSelectedArticle} from "services/ArticleServices";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelectedModels = () => {

    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const {ref: name, ...restName} = register("name", {required: true, minLength: 5});
    const {ref: type, ...restType} = register("type");
    const {ref: unit, ...restUnit} = register("unit", {required: true, minLength: 5});

    const [off, setOff] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeArticle, setActiveArticle] = useState(null);
    const [openDeleteCatModal, setOpenDeleteCatModal] = useState(false);
    const [openDeleteArtModal, setOpenDeleteArtModal] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [selectedArticles, setSelectedArticles] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [openInfo, setOpenInfo] = useState(true);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleDeleteCatModal = () => {
        setOpenDeleteCatModal(!openDeleteCatModal);
    };

    const handleDeleteArtModal = () => {
        setOpenDeleteArtModal(!openDeleteArtModal);
    };

    const retrieveSelectedArticles = async () => {
        let data = await getSelectedArticles();
        console.log(data);

        setSelectedArticles(data);
    }

    const retrieveSelectedCategories = async () => {
        let data = await getSelectedCategories();
        console.log(data);

        setSelectedCategories(data);
    }

    const delCategory = async () => {
        setOpenDeleteCatModal(false);
        let category_id = activeCategory.item.id;

        try {
            await deleteSelectedCategeory(category_id);

            setAlertMessage("Category sucessfully removed !");
            setAlertType("success");
            handleOpenAlert();

            retrieveSelectedCategories();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const delArticle = async () => {
        setOpenDeleteArtModal(false);
        let article_id = activeArticle.item.id;

        try {
            await deleteSelectedArticle(article_id);

            setAlertMessage("Article sucessfully removed !");
            setAlertType("success");
            handleOpenAlert();

            retrieveSelectedArticles();

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    useEffect(() => {

        const retrieveSelectedArticles = async () => {
            let data = await getSelectedArticles();
            setSelectedArticles(data);
        }

        const retrieveSelectedCategories = async () => {
            let data = await getSelectedCategories();
            setSelectedCategories(data);
        }

        retrieveSelectedCategories();
        retrieveSelectedArticles();
        setLoading(false);
    }, []);

    return (
        <>
            <Dialog open={openDeleteCatModal} onClose={handleDeleteCatModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Remove from list</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to remove this from selected category ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCatModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={delCategory}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openDeleteArtModal} onClose={handleDeleteArtModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Remove from list</DialogTitle>
                    <DialogContent>
                        <h3 className="mb-0">Are you sure you want to remove this from selected articles ?</h3>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteArtModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            onClick={delArticle}
                            color="danger"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>


            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={6000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <FeatureHeader off={off}/>
            {/* Page content */}
            {isLoading ? <ReactLoading type={"spin"} color="yellow"/> : <>
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">All Selected Categories</h3>
                                    <Nav className="justify-content-end" pills>

                                        {openInfo ?
                                            <i className="fas fa-arrow-up text-success mr-3" style={{cursor: 'pointer'}}
                                               onClick={() => setOpenInfo(!openInfo)}/>
                                            : <i className="fas fa-arrow-down text-warning mr-3"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => setOpenInfo(!openInfo)}/>
                                        }
                                    </Nav>
                                </CardHeader>
                                {openInfo &&
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Category name</th>
                                            <th scope="col">Number of articles</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            selectedCategories?.map((item) =>
                                                <tr key={item.id}>
                                                    <th scope="row">
                                                        <Media className="align-items-center">
                                                            <Media>
                                                                <span className="mb-0 text-sm">
                                                                    {item.name}
                                                                </span>
                                                            </Media>
                                                        </Media>
                                                    </th>
                                                    <td>{item.number_of_articles}</td>
                                                    <td className="text-right">
                                                        <div className="d-flex px-2 py-1">
                                                            <Tooltip title="Remove from list" placement="top">
                                                                <span
                                                                    className='material-icons'
                                                                    onClick={() => {
                                                                        setActiveCategory({item});
                                                                        handleDeleteCatModal();
                                                                    }}
                                                                    style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                    delete
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>}
                                <CardFooter className="py-4">
                                    <Row>

                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Container className="mt-5" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">All Selected Articles</h3>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Artilce name</th>
                                        <th scope="col">Article price</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        selectedArticles?.map((item) =>
                                            <tr key={item.id}>
                                                <th scope="row">
                                                    <Media className="align-items-center">
                                                        <Media>
                                                                <span className="mb-0 text-sm">
                                                                    {item.name}
                                                                </span>
                                                        </Media>
                                                    </Media>
                                                </th>
                                                <td>{item.price}</td>
                                                <td className="text-right">
                                                    <div className="d-flex px-2 py-1">
                                                        <Tooltip title="Remove from list" placement="top">
                                                                <span
                                                                    className='material-icons'
                                                                    onClick={() => {
                                                                        setActiveArticle({item});
                                                                        handleDeleteArtModal();
                                                                    }}
                                                                    style={{cursor: 'pointer', color: 'firebrick'}}>
                                                                    delete
                                                                </span>
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <Row>

                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>}
        </>
    );
};

export default SelectedModels;
