import Index from "views/Index.js";
import Articles from "views/examples/Articles.js";
import CreateAdmin2 from "./views/examples/admin/CreateAdmin2";
import ManageUsers from "./views/examples/user/ManageUsers";
import Category from "./views/examples/Category";
import Vehicles from "views/examples/Vehicles";
import Profile from "./views/examples/user/Profile";
import Chat from "views/examples/Chat";
import AllChats from "views/examples/AllChats";
import MyChats from "views/examples/MyChats";
import Markdown from "./views/examples/markdown";
import Feature from "./views/examples/Feature";
import UserSummary from "./views/examples/user/UserSummary";
import PurchaseUserDetails from "./views/examples/user/PurchaseUserDetails";

const super_admin_routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/manage-admin",
    name: "Manage Administrator",
    icon: "ni ni-single-02 text-yellow",
    component: CreateAdmin2,
    layout: "/admin",
  },
  {
    path: "/manage-users",
    name: "Manage Users",
    icon: "ni ni-tv-2 text-green",
    component: ManageUsers,
    layout: "/admin",
  },
  {
    path: "/manage-categories",
    name: "News Page",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Category,
    layout: "/admin",
  },
  {
    path: "/manage-articles",
    name: "Articles page",
    icon: "ni ni-bullet-list-67 text-red",
    component: Articles,
    layout: "/admin",
  },
  {
    path: "/manage-vehicles",
    name: "Vehicles board",
    icon: "ni ni-bullet-list-67 text-green",
    component: Vehicles,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/manage-features",
    name: "Features",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Feature,
    layout: "/admin",
  },
  {
    path: "/manage-all-chats",
    name: "All Chats",
    icon: "ni ni-single-02 text-yellow",
    component: AllChats,
    layout: "/admin",
  },
  {
    path: "/manage-chats",
    name: "My Chats",
    icon: "ni ni-single-02 text-yellow",
    component: MyChats,
    layout: "/admin",
  },
  // {
  //   path: "/manage-preview",
  //   name: "My views",
  //   icon: "ni ni-single-02 text-yellow",
  //   layout: "/admin",
  //   component: Markdown,
  // },
  {
    path: "/users/details",
    name: "UserSummary",
    icon: "ni ni-circle-08 text-pink",
    component: UserSummary,
    layout: "/admin",
  },
  {
    path: "/users/purchase/details",
    name: "Purchase",
    icon: "ni ni-circle-08 text-pink",
    component: PurchaseUserDetails,
    layout: "/admin",
  },
];
export default super_admin_routes;
