import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Container,
    Row,
    Button,
    Col,
    UncontrolledTooltip,
    Table,
    Media, Form, FormGroup, Input,
} from "reactstrap";
// core components
import ChatHeader from "components/Headers/ChatHeader.js";
import React, {useContext, useEffect, useState} from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import {AuthContext} from "context/AuthContext";
import moment from "moment";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {getChats, getChatsPaginated} from "services/ChatServices";
import {useHistory} from "react-router-dom";
import {takeChat, continueChat} from "services/ChatServices";
import {getAllPaginatedPurchases, getPurchasesPaginated} from "services/PurchaseServices";
import {changePurchaseStatus} from "services/PurchaseServices";
import {computeNumerotation} from "../../constants";
import ReactLoading from "react-loading";
import { allPaginatedPurchases } from "services/PurchaseServices";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Purchases = (props) => {

    const {user} = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [purchases, setPurchases] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [isLoading, setLoading] = useState(true);
    const [newStatus, setNewStatus] = useState("0");
    const history = useHistory();

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    useEffect(() => {
        const retrievePurchases = async () => {
            let response = await allPaginatedPurchases();
            setPurchases(response.data);
            setLastPage(response.meta.last_page);
            setCurrentPage(response.meta.current_page);
            setLoading(false);
        }

        retrievePurchases();
    }, []);

    const retrievePurchases = async () => {
        let response = await allPaginatedPurchases();
        setPurchases(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    }

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        let response = await allPaginatedPurchases(parseInt(requestedPage), itemPerPage);
        setPurchases(response.data);
        //setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        setItemPerPage(nb);
        setNbPageElement(parseInt(nb));
        let response = await allPaginatedPurchases(undefined, parseInt(nb));
        setPurchases(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
    }

    const goToPurchase = (e) => {

        let purchase_id = parseInt(e.target.getAttribute("value"));

        props.history.push({
            pathname: '/admin/single-purchase',
            search: '?purchaseId=' + purchase_id,
        });

    }

    const handleChangeStatus = () => {
        handleStatusModal();
    };

    const changePostStatus = async () => {
        let purchase_id = activeObject.item.id;
        setOpenStatusModal(false);

        if(newStatus == "0"){

            setAlertMessage("Please select a status !");
            setAlertType("error");
            handleOpenAlert(); 

        }else{
            try {
                await changePurchaseStatus(purchase_id, newStatus);
                retrievePurchases();
                setAlertMessage("Purchased status changed sucessfully");
                setAlertType("success");
                handleOpenAlert();
    
            } catch (error) {
                setAlertMessage(error.message);
                setAlertType("error");
                handleOpenAlert();
            }
        }

    }

    const handleStatus = (event) => {
        setNewStatus(event.target.value);
    };


    return (
        <>
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "right"}} open={openAlert} autoHideDuration={6000}
                      onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openStatusModal} onClose={handleStatusModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Status : {activeObject?.item.status}</DialogTitle>
                <DialogContent>
                    <CardBody>

                        <div className="pl-lg-4">
                            <Row>
                                <Col md="12">
                                    <p>Choose a state </p>
                                    <FormGroup>
                                        {activeObject?.item.status === "SUCCESS" &&                                     
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleStatus}
                                                id="exampleSelect">
                                                <option value="0">---Select a status---</option>
                                                <option value="4">SENT</option>
                                                <option value="7">CANCELLED</option>
                                            </Input> 
                                        }
                                        {activeObject?.item.status === "SENT" &&                                     
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleStatus}
                                                id="exampleSelect">
                                                <option value="0">---Select a status---</option>
                                                <option value="5">RECEIVED</option>
                                            </Input> 
                                        }
                                        {activeObject?.item.status === "RECEIVED" &&                                     
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleStatus}
                                                id="exampleSelect">
                                                <option value="0">---Select a status---</option>
                                                <option value="6">RETURNED</option>
                                            </Input> 
                                        }
                                        {activeObject?.item.status === "RETURNED" &&                                     
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleStatus}
                                                id="exampleSelect">
                                                <option value="0">---Select a status---</option>
                                                <option value="7">CANCELLED</option>
                                            </Input> 
                                        }
                                        {activeObject?.item.status === "NOT_RECEIVED" &&                                     
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleStatus}
                                                id="exampleSelect">
                                                <option value="0">---Select a status---</option>
                                                <option value="4">SENT</option>
                                                <option value="7">CANCELLED</option>
                                            </Input> 
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={handleStatusModal} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        onClick={changePostStatus}
                        color="primary"
                    >
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
            <ChatHeader/>
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Purchases Here</h3>
                            </CardHeader>
                            {isLoading ? <ReactLoading type={"spin"} color="yellow" /> :
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Purchase ID</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Payment Means</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {

                                    purchases?.map((item, index) =>
                                        <>

                                            <tr key={item.id}>
                                                <th scope="row">
                                                    <span className="mb-0 text-sm">
                                                        {computeNumerotation(currentPage, itemPerPage, index)}
                                                    </span>
                                                </th>
                                                <th scope="row">
                                                    <Media className="align-items-center">
                                                        <Media>
                                                                    <span className="mb-0 text-sm">
                                                                        {item.transaction_id}
                                                                    </span>
                                                        </Media>
                                                    </Media>
                                                </th>
                                                <th scope="row">{item.quantity}</th>
                                                <th scope="row">{item.payment_method}</th>
                                                <th scope="row">{item.amount}</th>
                                                <th scope="row">
                                                    {item.status}
                                                </th>
                                                <td>
                                                    {moment(item.created_at).format("YYYY/MM/DD kk:mm:ss")}
                                                </td>
                                                <td className="text-right">
                                                    <div className="d-flex px-2 py-1">

                                                        <Tooltip title="Change Status" placement="top">
                                                                        <span
                                                                            className='material-icons danger'
                                                                            value={item.id}
                                                                            onClick={(e) => {
                                                                                setActiveObject({item});
                                                                                setOpenStatusModal(true);
                                                                            }}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                color: 'orange'
                                                                            }}>
                                                                            edit
                                                                        </span>
                                                        </Tooltip>
                                                        <Tooltip title="View details" placement="top">
                                                                        <span
                                                                            className='material-icons danger'
                                                                            value={item.id}
                                                                            onClick={(e) => {
                                                                                setActiveObject({item});
                                                                                goToPurchase(e);
                                                                            }}
                                                                            style={{cursor: 'pointer', color: 'blue'}}>
                                                                            visibility
                                                                        </span>
                                                        </Tooltip>

                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                </tbody>
                            </Table>}
                            <CardFooter className="py-4">
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup className="mb-0" style={{width: "100px"}}>
                                                <select className="form-control" onChange={(e) => {
                                                    handleSearch(e)
                                                }}>
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25" selected>25</option>
                                                </select>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={'...'}
                                                pageCount={lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}

                                            />
                                        </nav>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Purchases;
